import { Button, Stack } from '@mui/material';
import { DateRange, dateRangeToTimeRangeMap } from './utils';

export const enum DateFilters {
  Last24Hours,
  Last7Days,
  Last30Days,
  Last90Days,
  Last180Days,
  Last1Year,
  Auto,
  All,
}

export const DATE_FILTER_LABEL: { [key in DateFilters]: string } = {
  [DateFilters.Last24Hours]: 'Last 24 Hours',
  [DateFilters.Last7Days]: 'Last 7 Days',
  [DateFilters.Last30Days]: 'Last 30 Days',
  [DateFilters.Last90Days]: 'Last 90 Days',
  [DateFilters.Last180Days]: 'Last 180 Days',
  [DateFilters.Last1Year]: 'Last 1 Year',
  [DateFilters.Auto]: '',
  [DateFilters.All]: '',
};

const DEFAULT_DATE_FILTER_KEYS: DateFilters[] = [
  DateFilters.Last24Hours,
  DateFilters.Last7Days,
  DateFilters.Last30Days,
  DateFilters.Last90Days,
];

export interface DateFilterConfig {
  label: string;
  key: DateFilters;
  dateRange?: [string, string];
}

export const dateFiltersConfigMap: {
  [key in DateFilters]: DateFilterConfig;
} = {
  [DateFilters.Last24Hours]: {
    key: DateFilters.Last24Hours,
    label: '24H',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last24Hours],
  },
  [DateFilters.Last7Days]: {
    key: DateFilters.Last7Days,
    label: '7D',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last7Days],
  },
  [DateFilters.Last30Days]: {
    key: DateFilters.Last30Days,
    label: '30D',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last30Days],
  },
  [DateFilters.Last90Days]: {
    key: DateFilters.Last90Days,
    label: '90D',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last90Days],
  },
  [DateFilters.Last180Days]: {
    key: DateFilters.Last180Days,
    label: '180D',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last180Days],
  },
  [DateFilters.Last1Year]: {
    key: DateFilters.Last1Year,
    label: '1Y',
    dateRange: dateRangeToTimeRangeMap[DateRange.Last1Year],
  },
  [DateFilters.Auto]: {
    key: DateFilters.Auto,
    label: 'Auto',
    dateRange: undefined,
  },
  [DateFilters.All]: {
    key: DateFilters.All,
    label: 'All',
    dateRange: undefined,
  },
};

interface ChartToolbarFilterProps {
  activeButton: DateFilters;
  handleButtonClick: (key: DateFilters) => void;
  dateFilterKeys?: DateFilters[];
}

export const ChartToolbarFilters = ({
  activeButton,
  handleButtonClick,
  dateFilterKeys = DEFAULT_DATE_FILTER_KEYS,
}: ChartToolbarFilterProps) => {
  const dateFiltersToRender: DateFilterConfig[] = dateFilterKeys.map(
    (key) => dateFiltersConfigMap[key]
  );

  if (!dateFiltersToRender) {
    return null;
  }
  return (
    <Stack spacing={1} sx={{ mb: 1 }}>
      <Stack direction="row" spacing={1} sx={{ float: 'right' }}>
        {dateFiltersToRender.map((filterConfig) => (
          <Button
            className={activeButton === filterConfig.key ? 'active' : ''}
            key={filterConfig.key}
            variant={
              activeButton === filterConfig.key ? 'contained' : 'outlined'
            }
            size="small"
            sx={{
              fontSize: '11px',
              padding: '5px',
              height: '25px',
            }}
            onClick={() => handleButtonClick(filterConfig.key)}
          >
            {filterConfig.label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};
