import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const BORDER_RADIUS_SCALING_FACTOR = 1;

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          border: `1px solid ${theme.palette.grey[500_56]}`,
          borderRadius:
            Number(theme.shape.borderRadius) * BORDER_RADIUS_SCALING_FACTOR,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: {
          variant: 'body2',
          marginTop: theme.spacing(0.5),
        },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}
