import {
  AlationIconSVG,
  BigQueryIconSVG,
  ThoughtSpotIconSVG,
  JiraIconSVG,
  TableauIconSVG,
  DatabricksIconSVG,
  PagerDutyIconSVG,
} from '../icons';
import { Artifact, DataSourceType, IntegrationTypeProto } from '@ui/gql-client';
import Iconify from '../Iconify';
import { Box } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { GridValueGetterParams } from '@mui/x-data-grid-pro';

type DbTableMetadata = {
  databaseName: string;
  schemaName: string;
  tableName: string;
};

/**
 * This helper function constructs the FQN from a table metadata object, and throws an error if the
 * database name, schema name, or table name don't exist in the table metadata object.
 * @param {DbTableMetadata} dbTableMetadata - the table metadata object to construct a FQN from
 * @returns string representing the FQN of the table metadata
 */
export function getFQN(dbTableMetadata: DbTableMetadata) {
  for (const requiredField of ['databaseName', 'schemaName', 'tableName']) {
    if (!Object.prototype.hasOwnProperty.call(dbTableMetadata, requiredField)) {
      throw new Error(`${requiredField} not found in dbTableMetadata`);
    }
  }
  return `${dbTableMetadata['databaseName']}.${dbTableMetadata['schemaName']}.${dbTableMetadata['tableName']}`;
}

/**
 * This helper function extracts the FQN from an artifact object, without throwing an error if the
 * database name, schema name, or table name don't exist in the artifact object.
 * @param {Artifact} artifact - the artifact object to extract a FQN from
 * @returns string representing the FQN of the artifact, or undefined if the FQN cannot be extracted
 */
export function getFqnNoError(artifact: Artifact | undefined | null) {
  if (!artifact) {
    return undefined;
  }
  return artifact.dbTableMetadata &&
    artifact.dbTableMetadata?.tableName &&
    artifact.dbTableMetadata?.schemaName &&
    artifact.dbTableMetadata?.databaseName
    ? `${artifact.dbTableMetadata?.databaseName}.${artifact.dbTableMetadata?.schemaName}.${artifact.dbTableMetadata?.tableName}`
    : undefined;
}

export function artifactDataSourceTypeEquals(
  artifact: Artifact | undefined,
  dataSourceType: DataSourceType
) {
  return artifact?.configuration?.type === dataSourceType;
}

export const integrationTypeToDisplayNameInCopyText: {
  [key in IntegrationTypeProto]?: string;
} = {
  [IntegrationTypeProto.IntegrationTypeEmail]: 'email',
  [IntegrationTypeProto.IntegrationTypeSlack]: 'Slack',
  [IntegrationTypeProto.IntegrationTypeMicrosoftTeams]: 'Teams',
};

// NOTE: The keys in the below 2 maps must match the DataSourceType or IntegrationType enums
export const configTypeToIconifyMap: { [key: string]: string } = {
  Redshift: 'logos:aws-redshift',
  Snowflake: 'logos:snowflake-icon',
  Slack: 'logos:slack-icon',
  Looker: 'logos:looker-icon',
  MockDataGenerator: 'icon-park-solid:six-circular-connection',
  DBT: 'logos:dbt-icon',
  'dbt Cloud': 'logos:dbt-icon',
  MicrosoftTeams: 'logos:microsoft-teams',
};

export const configTypeToLibIconMap: { [key: string]: JSX.Element } = {
  ThoughtSpot: ThoughtSpotIconSVG,
  BigQuery: BigQueryIconSVG,
  Databricks: DatabricksIconSVG,
  Alation: AlationIconSVG,
  Jira: JiraIconSVG,
  Tableau: TableauIconSVG,
  PagerDuty: PagerDutyIconSVG,
};

export const getIconByConnectionType = (
  connectionType: string,
  size: string
) => {
  if (connectionType in configTypeToIconifyMap) {
    return (
      <Iconify
        icon={configTypeToIconifyMap[connectionType]}
        width={size}
        height={size}
      />
    );
  } else if (connectionType in configTypeToLibIconMap) {
    return (
      <Box component="span" sx={{ width: size, height: size, display: 'flex' }}>
        {configTypeToLibIconMap[connectionType]}
      </Box>
    );
  }
};

const connectionTypeToIconifyMap: {
  [key in IntegrationTypeProto | DataSourceType]?: string;
} = {
  [DataSourceType.DataSourceTypeRedshift]: 'logos:aws-redshift',
  [DataSourceType.DataSourceTypeSnowflake]: 'logos:snowflake-icon',
  [IntegrationTypeProto.IntegrationTypeSlack]: 'logos:slack-icon',
  [IntegrationTypeProto.IntegrationTypeLooker]: 'logos:looker-icon',
  [DataSourceType.DataSourceTypeMockDataGenerator]:
    'icon-park-solid:six-circular-connection',
  [IntegrationTypeProto.IntegrationTypeDbt]: 'logos:dbt-icon',
  [DataSourceType.DataSourceTypeDbt]: 'logos:dbt-icon',
  [IntegrationTypeProto.IntegrationTypeMicrosoftTeams]: 'logos:microsoft-teams',
  [IntegrationTypeProto.IntegrationTypeEmail]: 'ic:round-email',
};

const connectionTypeToLibIconMap: {
  [key in IntegrationTypeProto | DataSourceType]?: JSX.Element;
} = {
  [IntegrationTypeProto.IntegrationTypeThoughtspot]: ThoughtSpotIconSVG,
  [DataSourceType.DataSourceTypeBigquery]: BigQueryIconSVG,
  [IntegrationTypeProto.IntegrationTypeAlation]: AlationIconSVG,
  [IntegrationTypeProto.IntegrationTypeJira]: JiraIconSVG,
  [IntegrationTypeProto.IntegrationTypeTableau]: TableauIconSVG,
  [IntegrationTypeProto.IntegrationTypePagerDuty]: PagerDutyIconSVG,
};

export const getIconForConnectionType = (
  connectionType: IntegrationTypeProto | DataSourceType,
  size: string,
  sx?: any
) => {
  if (connectionType in connectionTypeToIconifyMap) {
    return (
      <Iconify
        icon={connectionTypeToIconifyMap[connectionType] as string}
        width={size}
        height={size}
        sx={sx}
      />
    );
  } else if (connectionType in connectionTypeToLibIconMap) {
    return (
      <Box
        component="span"
        sx={{ width: size, height: size, display: 'flex', ...sx }}
      >
        {connectionTypeToLibIconMap[connectionType]}
      </Box>
    );
  }
  console.warn('No icon found for connection type:', connectionType);
  return <></>;
};

export const getTableColumns = (labelsMap: any) => {
  return Object.keys(labelsMap).reduce((acc, key) => {
    if (!labelsMap[key].disabled) {
      acc.push({
        field: key,
        flex: 1,
        ...labelsMap[key],
      });
    }

    return acc;
  }, [] as any[]);
};

export const getKeyByValue = (obj: any, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getErrorFromApolloObject = (error: ApolloError): string => {
  return (
    (error.graphQLErrors[0].extensions?.exception as any).details ??
    error.message ??
    'Something went wrong.'
  );
};

export const cardWrappedDataGridStyles = {
  '& .MuiDataGrid-columnHeaders': {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
};

export const defaultToZeroValueGetter = (params: GridValueGetterParams) => {
  return params.value ? params.value : 0;
};

export const dataSourceTypeToLabelMap: { [key in DataSourceType]?: string } = {
  [DataSourceType.DataSourceTypeSnowflake]: 'Snowflake',
  [DataSourceType.DataSourceTypeBigquery]: 'BigQuery',
  [DataSourceType.DataSourceTypeRedshift]: 'Redshift',
  [DataSourceType.DataSourceTypeDatabricks]: 'Databricks',
};
