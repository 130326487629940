import React, { useState } from 'react';
import { Dialog, Box } from '@mui/material';
import { Iconify, useAuth } from '@ui/components';
import { TenantInfo, TenantType } from '@ui/gql-client';
import MiniNavBar, { MiniNavBarItem } from './components/AccountSettingsNavBar';
import MembersTab from './components/MembersTab';
import SettingsTab from './components/SettingsTab';
import ExternalNotificationsTab from './components/ExternalNotificationsTab';

interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
  currentTenant: TenantInfo;
}

const TenantSettingsDialog: React.FC<SettingsDialogProps> = ({
  open,
  onClose,
  currentTenant,
}) => {
  const { user } = useAuth();
  const isAdmin = user?.email === currentTenant.adminEmail;

  const ACCOUNT_SETTINGS_TABS: MiniNavBarItem[] = [
    {
      value: 'account',
      label: 'Account Details',
      icon: <Iconify icon={'octicon:gear-16'} />,
      isEnabled: true,
      component: (
        <SettingsTab
          currentTenant={currentTenant}
          isAdmin={isAdmin}
          showHeader={true}
        />
      ),
    },
    {
      value: 'members',
      label: 'Members',
      icon: <Iconify icon={'octicon:people-16'} />,
      isEnabled: currentTenant.tenantType !== TenantType.TenantTypePersonal,
      component: <MembersTab currentTenant={currentTenant} isAdmin={isAdmin} />,
    },
  ];

  const [currentTab, setCurrentTab] = useState('account');
  const handleChangeTab = (value: string) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          p: 0,
          margin: 'auto',
          '& .MuiPaper-root': {
            width: '900px',
            maxWidth: 'calc(100vw - 100px)',
            height: 'calc(100vh - 100px)',
            maxHeight: '700px',
          },
        }}
      >
        <Box
          id="account-settings-container"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            id="account-settings-nav-bar"
            sx={{
              height: '100%',
              width: '250px',
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <MiniNavBar
              currentValue={currentTab}
              menuItems={ACCOUNT_SETTINGS_TABS.filter((tab) => tab.isEnabled)}
              onChange={handleChangeTab}
            />
          </Box>
          <Box
            id="account-settings-content-container"
            sx={{
              position: 'relative',
              zIndex: 1,
              flexGrow: 1,
              height: '100%',
              p: 3,
              overflowY: 'auto',
            }}
          >
            {ACCOUNT_SETTINGS_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default TenantSettingsDialog;
