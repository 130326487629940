import {
  Stack,
  Typography,
  Divider,
  Button,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  BasicErrorMessage,
  ConfirmationDialog,
  useAuth,
  useUrlParamContext,
} from '@ui/components';
import {
  TenantInfo,
  useDisableTenantMutation,
  useUpdateTenantInfoMutation,
} from '@ui/gql-client';
import { useEffect, useState } from 'react';

interface SettingsTabProps {
  currentTenant: TenantInfo;
  isAdmin: boolean;
  showHeader: boolean;
}

const SettingsTab: React.FC<SettingsTabProps> = ({
  currentTenant,
  isAdmin,
  showHeader,
}) => {
  const { user } = useAuth();
  const userVars = {
    userId: user?.sub ?? '',
    email: user?.email ?? '',
  };
  const { urlParam, updateUrlParam } = useUrlParamContext();

  const [isDeactivationDialogOpen, setDeactivationDialogOpen] = useState(false);

  const handleOpenDeactivationDialog = () => {
    setDeactivationDialogOpen(true);
  };

  const handleCloseDeactivationDialog = () => {
    setDeactivationDialogOpen(false);
  };

  const [disableTenantMutation, { loading: disableTenantLoading }] =
    useDisableTenantMutation();

  const handleConfirmDisableAccount = () => {
    disableTenantMutation({
      variables: { ...userVars },
      onCompleted: () => {
        updateUrlParam('0');
        window.location.replace('/');
      },
    });

    setDeactivationDialogOpen(false);
  };

  const [displayName, setDisplayName] = useState(
    currentTenant.tenantDisplayName ?? ''
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // Reset state when currentTenant changes
  useEffect(() => {
    setDisplayName(currentTenant.tenantDisplayName ?? '');
    setHasUnsavedChanges(false);
  }, [currentTenant.tenantDisplayName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDisplayName(newValue);
    setHasUnsavedChanges(newValue !== currentTenant.tenantDisplayName);
  };

  const [
    updateTenantInfo,
    { loading: saveUpdateLoading, error: saveUpdateError },
  ] = useUpdateTenantInfoMutation();

  // mutation to set display name
  const handleSaveClick = () => {
    updateTenantInfo({
      variables: {
        ...userVars,
        tenantDisplayName: displayName,
      },
      refetchQueries: ['getAllTenantInfosForUser'],
    });
    setHasUnsavedChanges(false);
  };

  const accountInfo = [
    {
      label: 'Name',
      value: currentTenant.tenantName,
    },
    {
      label: 'Admin',
      value: currentTenant.adminEmail,
    },
    {
      label: 'Current plan',
      value: currentTenant.tenantTypeDisplayName,
    },
  ];

  return (
    <Box
      id="settings-tab-container"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      flexGrow={1}
      rowGap={5}
      my={2}
      sx={{
        '& .MuiDivider-root': {
          mt: 1,
          mb: 1.75,
        },
      }}
    >
      <Box id="settings-tab-account-details-container">
        {showHeader && (
          <>
            <Typography variant="subtitle1" fontWeight={600}>
              Account details
            </Typography>
            <Divider />
          </>
        )}
        <Typography
          variant="body2"
          fontWeight={500}
          component="label"
          htmlFor="account-display-name-input"
        >
          Display name
        </Typography>
        <Stack direction="row" spacing={1} my={0.5} alignItems="center">
          <TextField
            id="account-display-name-input"
            size="small"
            margin="none"
            variant="outlined"
            placeholder="e.g. acme-analytics"
            value={displayName}
            onChange={handleInputChange}
            disabled={!isAdmin}
            sx={{
              '& .MuiInputBase-root': {
                height: 36,
                fontSize: '0.875rem',
              },
            }}
          />
          {hasUnsavedChanges && (
            <Box sx={{ position: 'relative' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                disabled={saveUpdateLoading}
              >
                Save
              </Button>
              {saveUpdateLoading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'grey.500',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-10px',
                    marginLeft: '-10px',
                  }}
                />
              )}
            </Box>
          )}
        </Stack>
        {saveUpdateError && (
          <BasicErrorMessage message={saveUpdateError.message} />
        )}
        {!isAdmin && (
          <Typography variant="caption" color="text.secondary">
            Please contact your account admin to change the display name.
          </Typography>
        )}
        <Stack spacing={2} mt={3}>
          {accountInfo.map((info) => (
            <Stack key={info.label} spacing={0.5}>
              <Typography variant="body2" fontWeight={500}>
                {info.label}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
              >
                {info.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
      {isAdmin && (
        <Box id="settings-tab-account-deactivation-container">
          <Typography variant="subtitle1" fontWeight={600}>
            Deactivate account
          </Typography>
          <Divider />
          <Typography variant="body2">
            Once you deactivate the account, you won't be able to sign back into
            this account.
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you want to recover your information, contact support@revefi.com
            within 30 days.
          </Typography>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleOpenDeactivationDialog}
          >
            Deactivate account
          </Button>
          <ConfirmationDialog
            open={isDeactivationDialogOpen}
            onConfirm={handleConfirmDisableAccount}
            onClose={handleCloseDeactivationDialog}
            loading={disableTenantLoading}
            dialogTitle="Are you sure you want to do this?"
            dialogContent={
              <Typography variant="body2">
                You will no longer be able to log in. After 30 days, we will
                permanently delete all of your data associated with this
                account.
              </Typography>
            }
            action="Yes, I'm sure"
          />
        </Box>
      )}
    </Box>
  );
};

export default SettingsTab;
