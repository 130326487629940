/**
 * @fileoverview Presentational component for displaying a tenant in the tenant switcher menu
 * Created on September 21, 2023
 */
import React from 'react';
import { MenuItem, Typography, Stack } from '@mui/material';
import { Iconify, Label } from '@ui/components';
import { TenantType } from '@ui/gql-client';

interface TenantMenuItemProps {
  tenantName: string;
  tenantType: TenantType;
  tenantTypeDisplayName: string;
  tenantDisplayName: string;
  isCurrentTenant: boolean;
  isUserAdmin: boolean;
  onClick: () => void;
}

const TenantMenuItem: React.FC<TenantMenuItemProps> = ({
  tenantName,
  tenantType,
  tenantTypeDisplayName,
  tenantDisplayName,
  isCurrentTenant,
  isUserAdmin,
  onClick,
}) => {
  return (
    <MenuItem onClick={onClick} selected={isCurrentTenant}>
      <Stack sx={{ flexGrow: 1 }}>
        <Stack spacing={1}>
          <Typography variant="body1">
            {tenantDisplayName ? tenantDisplayName : tenantName}
            {isUserAdmin && (
              <Label variant="ghost" color="primary" sx={{ ml: 1 }}>
                <Typography variant="overline" fontSize="0.625rem">
                  Admin
                </Typography>
              </Label>
            )}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {tenantTypeDisplayName}
        </Typography>
      </Stack>
      {isCurrentTenant && (
        <Iconify icon="bi:check" color="primary.main" fontSize={28} />
      )}
    </MenuItem>
  );
};

export default TenantMenuItem;
