// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Divider } from '@mui/material';
// type
import { NavListProps, NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';

import { useFeatureFlagEnabled } from 'posthog-js/react';

// ----------------------------------------------------------------------

export const ListSubheaderStyle: any = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other} sx={{ pt: 4 }}>
      {navConfig.map((group, index) => (
        <List key={`group.subheader-${index}`} disablePadding sx={{ px: 2 }}>
          {group.subheader && (
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>
          )}

          {group.items.map((list) =>
            Object.prototype.hasOwnProperty.call(list, 'featureFlag') ? (
              <NavListWithFeatureFlag
                key={list.title + list.path}
                navItemList={list}
                isCollapse={isCollapse}
              />
            ) : (
              <NavListRoot
                key={list.title + list.path}
                list={list}
                isCollapse={isCollapse}
              />
            )
          )}
          <Divider sx={{ mb: 0.5 }} />
        </List>
      ))}
    </Box>
  );
}

interface NavListWithFeatureFlagProps {
  navItemList: NavListProps;
  isCollapse: boolean;
}
function NavListWithFeatureFlag({
  navItemList,
  isCollapse,
}: NavListWithFeatureFlagProps) {
  // Checks for feature flag of a nav item
  const flagName = navItemList.featureFlag;

  const featureFlagEnabled = useFeatureFlagEnabled(flagName as string);

  return featureFlagEnabled ? (
    <NavListRoot
      key={navItemList.title + navItemList.path}
      list={navItemList}
      isCollapse={isCollapse}
    />
  ) : (
    <></>
  );
}
