export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2.37298 13.0112L12.9822 2.40199C13.2474 2.13676 13.5789 2.00414 13.9105 2.00414H20.6738C21.4032 2.00414 22 2.60091 22 3.33029V10.0605C22 10.4252 21.8674 10.7567 21.6022 10.9888L10.993 21.598C10.4625 22.1285 9.63365 22.1285 9.10319 21.598L2.37298 14.8678C1.87567 14.3705 1.87567 13.5085 2.37298 13.0112ZM16.6954 8.96643C17.6237 8.96643 18.3531 8.23705 18.3531 7.30874C18.3531 6.38044 17.6237 5.65106 16.6954 5.65106C15.7671 5.65106 15.0377 6.38044 15.0377 7.30874C15.0377 8.23705 15.7671 8.96643 16.6954 8.96643Z"
      fill="#637381"
    />
  </svg>
);
