/**
 * @fileoverview App-wide state management for user and tenants (accounts).
 * Created Oct 2, 2023
 */
import {
  DomainAllowedStatus,
  TenantInfo,
  TenantSubtype,
  TenantType,
} from '@ui/gql-client';
import { atom } from 'jotai';

// user info

interface UserInfo {
  userId: string;
  userEmail: string;
  userNotAuthorized: boolean;
  noTenantPresent: boolean;
  revefiUserId: string;
  userNotAuthorizedReason?: DomainAllowedStatus | null | undefined;
}

export const userInfoAtom = atom<UserInfo | undefined>(undefined);

// tenant info
// check for isCurrentTenant when using this
export const tenantInfoAtom = atom<TenantInfo | undefined>(undefined);

export const tenantTypeSubtypeAtom = atom((get) => ({
  tenantType: get(tenantInfoAtom)?.tenantType,
  tenantSubtype: get(tenantInfoAtom)?.tenantSubtype,
}));

export const isDbtOnlyTenantAtom = atom(
  (get) =>
    get(tenantInfoAtom)?.tenantType === TenantType.TenantTypeTeam &&
    get(tenantInfoAtom)?.tenantSubtype === TenantSubtype.TenantSubtypeDbt
);
