import { useAuth } from '@ui/components';
// components

// @mui
import { CircularProgress, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function LoginForm() {
  const { pathname, search } = useLocation();
  const { loginWithRedirect } = useAuth();
  loginWithRedirect({
    appState: {
      returnTo: pathname + search,
    },
  });
  return (
    <Container>
      <CircularProgress
        size={48}
        sx={{
          color: 'grey.500',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    </Container>
  );
}
