import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

//keys of the search params that we want to retain while navigating to a new route
const persistedSearchParamsKeys: string[] = ['dsId'];

/**
 * custom hook to get the search param string from the current location containing only the keys that we want to persist
 */
export default function usePersistedSearchParams() {
  const [searchParams] = useSearchParams();
  const persistedSearchParams = useMemo(() => {
    const searchParamsClone = new URLSearchParams(searchParams);
    searchParamsClone.forEach((value, key) => {
      if (!persistedSearchParamsKeys.includes(key))
        searchParamsClone.delete(key);
    });
    return searchParamsClone.toString();
  }, [searchParams]);

  return persistedSearchParams ? `?${persistedSearchParams}` : '';
}
