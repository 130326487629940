import { format, SqlLanguage } from 'sql-formatter';
import { DataSourceType } from '@ui/gql-client';

/**
 * The stand SQL dialect to support the best effort formatting.
 * https://github.com/sql-formatter-org/sql-formatter/blob/master/docs/language.md
 */
const defaultSqlLanguage: SqlLanguage = 'sql';

export const DATA_SOURCE_TYPE_SQL_LANGUAGE_MAPPING: {
  [key in DataSourceType]?: SqlLanguage;
} = {
  [DataSourceType.DataSourceTypeSnowflake]: 'snowflake',
  [DataSourceType.DataSourceTypeBigquery]: 'bigquery',
  [DataSourceType.DataSourceTypeRedshift]: 'redshift',
};

/**
 * Returns the SQL dialect for the given data source type.
 * @param dataSourceType
 * @returns {SqlLanguage} SQL dialect for the given data source type. If the data source type is not
 * recognized, returns the default SQL dialect 'sql' to support the best effort formatting.
 */
export function getSqlLanguage(dataSourceType: DataSourceType): SqlLanguage {
  if (!dataSourceType) {
    return defaultSqlLanguage;
  }

  return (
    DATA_SOURCE_TYPE_SQL_LANGUAGE_MAPPING[dataSourceType] || defaultSqlLanguage
  );
}

/**
 * Result of formatting SQL query.
 * If formatting is successful, `sql` will contain the formatted SQL query.
 * If formatting fails, `sql` will contain the original SQL query and `error` will contain the error.
 */
export type FormatSqlResult = {
  sql: string;
  error?: any;
};

/**
 * Formats the given SQL query as per the dialect of the given data source type.
 * @param sql SQL query to format
 * @param dataSourceType Data source type
 */
export function formatSql(
  sql: string,
  dataSourceType: DataSourceType
): FormatSqlResult {
  const sqlLanguage = getSqlLanguage(dataSourceType);
  try {
    const formattedSql = format(sql, { language: sqlLanguage });
    return {
      sql: formattedSql,
    };
  } catch (e) {
    return {
      sql: sql,
      error: e,
    };
  }
}
