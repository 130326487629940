import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
// components
import { Page, Iconify } from '@ui/components';
import ErrorImage from '../../../assets/fixing-error.png';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UnexpectedError() {
  return (
    <Page title="Unexpected Error">
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <m.div>
          <img
            src={ErrorImage}
            width={300}
            alt="graphic illustration for unexpected error"
          />
          <Typography
            variant="h3"
            paragraph
            mt={5}
            style={{
              background: '-webkit-linear-gradient(162deg, #FCC101, #161c24)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Oops! Something went wrong...
          </Typography>
        </m.div>

        <m.div>
          <Typography sx={{ color: 'text.secondary' }}>
            We are working on it and we'll get it fixed ASAP!
          </Typography>
        </m.div>
      </ContentStyle>
    </Page>
  );
}
