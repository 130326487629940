// @mui
import {
  Box,
  Card,
  CardProps,
  Chip,
  Link as ExtLink,
  Stack,
  Typography,
} from '@mui/material';
// utils
// components
import { useState, useEffect } from 'react';
import { getTableColumns } from '@ui/components';
import { fFloorNumber } from '@ui/components';

import { DataGridPro, GridValueGetterParams } from '@mui/x-data-grid-pro';

import { Incident, useGetIncidentListQuery } from '@ui/gql-client';

// ----------------------------------------------------------------------

type Props = CardProps;

const PAGE_SIZE = 15;

const GRID_COLUMN_LABELS: object = {
  status: {
    headerName: 'Status',
    align: 'left',
    maxWidth: 100,
  },
  summary: {
    headerName: 'Summary',
    align: 'left',
    minWidth: 500,
    renderCell: (params: GridValueGetterParams) => (
      <ExtLink href={params.row.url} target="_blank" rel="noreferrer">
        <Box sx={{ wordWrap: 'break-word', maxWidth: 1000 }}>
          {params.row.summary}
        </Box>
      </ExtLink>
    ),
  },
  owner: {
    headerName: 'Owner',
    align: 'left',
    renderCell: (params: GridValueGetterParams) => {
      let emailAddress = '';
      if (params.value) {
        try {
          emailAddress = JSON.parse(params.value).emailAddress;
        } catch (e) {
          emailAddress = 'Not Assigned';
        }
      }
      return (
        <Stack>
          <Chip label={emailAddress} />
        </Stack>
      );
    },
    minWidth: 200,
  },
  createdDate: {
    headerName: 'Created On',
    align: 'left',
    maxWidth: 200,
  },
  lastUpdatedDate: {
    headerName: 'Updated On',
    align: 'left',
    maxWidth: 200,
  },
};

export default function IncidentDetails({ ...other }: Props) {
  const [incidentList, setIncidentList] = useState<any>();
  const [page, setPage] = useState(0);
  const rowsData: any[] = [];
  const [rowCount, setRowCount] = useState(0);

  const handlePageChange = (newPage: number) => {
    // We have the cursor, we can allow the page transition.

    if (rowsData.length || newPage < page) {
      setPage(newPage);
    }
  };

  const { data, loading, error, fetchMore } = useGetIncidentListQuery();

  useEffect(() => {
    if (!loading && data) {
      if (data.incidents) {
        setIncidentList(data.incidents);
        setRowCount(data.incidents.length);
      }
    }
  }, [data, loading]);

  if (incidentList) {
    incidentList.forEach((incident: Incident) => {
      rowsData.push({
        id: incident.id,
        status: incident.status,
        owner: incident.jiraData.assignedTo,
        summary: incident.jiraData?.summary,
        url: incident.externalReference?.url,
        createdDate: new Date(
          fFloorNumber(incident.jiraData?.createdDate?.seconds as number) * 1000
        ).toDateString(),
        lastUpdatedDate: new Date(
          fFloorNumber(incident.jiraData?.lastUpdatedDate?.seconds as number) *
            1000
        ).toDateString(),
      });
    });
  }

  const colNames = getTableColumns(GRID_COLUMN_LABELS);
  return (
    <Card
      sx={{
        p: 0,
        mt: 1.5,
        '& .MuiDataGrid-columnHeader': {
          height: 56,
        },
      }}
    >
      <DataGridPro
        pagination
        page={page}
        onPageChange={handlePageChange}
        paginationMode="server"
        sortingOrder={['desc', 'asc', null]}
        rows={!loading ? rowsData : []}
        rowCount={rowCount}
        columns={colNames}
        rowsPerPageOptions={[PAGE_SIZE]}
        hideFooterSelectedRowCount={true}
        disableColumnMenu={true}
        rowHeight={68}
        autoHeight
        getRowId={(row) => {
          return `${row.id}`;
        }}
        disableSelectionOnClick
        loading={loading}
      />
      {error && <Typography variant="body2">{error.message}</Typography>}
    </Card>
  );
}
