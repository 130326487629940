import React, {
  useRef,
  useImperativeHandle,
  useState,
  forwardRef,
} from 'react';
import Iconify from './Iconify';

interface FullScreenProps {
  children: React.ReactNode;
}

export interface FullScreenMethods {
  toggleFullScreen: () => void;
  isFullScreen: boolean;
}

//Create a full screen component for passed in children (Any component can be full screen)

const FullScreenComponent = forwardRef<FullScreenMethods, FullScreenProps>(
  ({ children }, ref) => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreen = () => {
      const target = targetRef.current;
      if (target) {
        if (!isFullScreen) {
          if (target.requestFullscreen) {
            target.requestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
        setIsFullScreen(!isFullScreen);
      }
    };

    useImperativeHandle(ref, () => ({
      toggleFullScreen: handleFullScreen,
      isFullScreen,
    }));

    return (
      <div
        ref={targetRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        {children}
      </div>
    );
  }
);

export default FullScreenComponent;
