import { useMemo, useState } from 'react';
import { useAuth, useUrlParamContext } from '@ui/components';

// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar } from '@mui/material';
// components
import { Iconify, IconButtonAnimate, MenuPopover } from '@ui/components';
import TenantMenuItem from './components/TenantMenuItem';
import {
  useGetAllTenantInfosForUserQuery,
  useSetTenantForUserMutation,
  TenantInfo,
} from '@ui/gql-client';
import TenantSettingsDialog from './TenantSettings';
import { useNavigate } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';
import { useFeatureFlagEnabled } from 'posthog-js/react';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [tenantSettingsOpen, setTenantSettingsOpen] = useState<boolean>(false);
  const isUserManagementFeatureEnabled =
    useFeatureFlagEnabled('enableUserManagement') ?? false;
  const { user, logout } = useAuth();
  const theme = useTheme();
  const { urlParam, updateUrlParam } = useUrlParamContext();

  const navigate = useNavigate();

  const userVars = {
    userId: user?.sub ?? '',
    email: user?.email ?? '',
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const { data, error } = useGetAllTenantInfosForUserQuery({
    variables: userVars,
  });
  const tenantInfos = data?.AllTenantInfosList;

  const currentTenant = useMemo(
    () => tenantInfos?.find((item) => item.isCurrentTenant),
    [tenantInfos]
  );

  const openAccountSettings = () => {
    if (isUserManagementFeatureEnabled) {
      navigate('/account/accountDetails');
      handleClose();
    } else {
      setTenantSettingsOpen(true);
      setOpen(null);
    }
  };

  const [setTenantForUserMutation] = useSetTenantForUserMutation();
  // TODO: Handle error by displaying toast on error
  const handleTenantSwitch = (tenant: TenantInfo) => {
    if (!tenant.isCurrentTenant) {
      setTenantForUserMutation({
        variables: {
          ...userVars,
          tenantId: tenant.tenantId,
        },
        onCompleted: () => {
          updateUrlParam('0');
          window.location.replace('/');
        },
      });
    }
    setOpen(null);
  };
  return (
    <>
      <IconButtonAnimate
        data-testid="account-popover-button"
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.8),
            },
          }),
        }}
      >
        {user?.picture ? (
          <Avatar
            alt={user?.name}
            src={user?.picture}
            sx={{ height: '33px', width: '33px' }}
          />
        ) : (
          <Iconify
            icon="carbon:user-avatar-filled"
            height="36px"
            width="36px"
          />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        data-testid="account-popover"
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          p: 0,
          mt: 1.75,
          ml: 1.25,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
            m: 1,
          },
          overflow: 'auto',
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {error && (
          <Typography
            variant="body2"
            display="flex"
            alignItems="center"
            color="error.main"
            sx={{ my: 1.5, px: 2.5 }}
          >
            <Iconify icon="ic:round-warning" fontSize={18} mr={0.5} />
            Error loading tenants!
          </Typography>
        )}
        {tenantInfos &&
          tenantInfos.map((tenant) => (
            <TenantMenuItem
              key={tenant.tenantName}
              tenantName={tenant.tenantName ?? ''}
              tenantDisplayName={tenant.tenantDisplayName ?? ''}
              tenantType={tenant.tenantType}
              tenantTypeDisplayName={tenant.tenantTypeDisplayName}
              isCurrentTenant={tenant.isCurrentTenant ?? false}
              isUserAdmin={user?.email === tenant.adminEmail}
              onClick={() => handleTenantSwitch(tenant)}
            />
          ))}

        <MenuItem
          onClick={() => {
            updateUrlParam('0');
            navigate('/signup');
          }}
        >
          <Typography
            variant="inherit"
            display="flex"
            fontWeight={600}
            alignItems="center"
            color="text.secondary"
          >
            <Iconify icon="ic:baseline-add" mr={1} width={20} height={20} />
            Add account
          </Typography>
        </MenuItem>

        {/* dark mode toggle */}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box marginTop={1} padding="8px 16px">
          <Box>
            <Typography
              sx={{ color: theme.palette.text.secondary }}
              variant="overline"
            >
              View Settings
            </Typography>
          </Box>
          <Box marginTop={1} display="flex">
            <Box display="flex" alignItems="center">
              <Typography variant="body2">Theme</Typography>
            </Box>
            <Box marginLeft={3}>
              <ThemeToggle></ThemeToggle>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={openAccountSettings}
          sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.9) }}
        >
          <Typography
            variant="inherit"
            fontWeight={600}
            display="flex"
            alignItems="center"
          >
            <Iconify icon="octicon:person-16" mr={1} width={20} height={20} />
            Account
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={{ color: 'error.main' }}
        >
          <Typography
            variant="inherit"
            fontWeight={600}
            display="flex"
            alignItems="center"
          >
            <Iconify icon="ic:round-logout" mr={1} width={20} height={20} />
            Logout
          </Typography>
        </MenuItem>
      </MenuPopover>
      {currentTenant ? (
        <TenantSettingsDialog
          open={tenantSettingsOpen}
          onClose={() => setTenantSettingsOpen(false)}
          currentTenant={currentTenant}
        />
      ) : null}
    </>
  );
}
