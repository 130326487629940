// components
import {
  Iconify,
  NavListProps,
  SnowflakeWarehouseSVG,
  TagsNavIconSVG,
} from '@ui/components';
import {
  DataSourceType,
  GlobalPrivilege,
  TenantSubtype,
  TenantType,
} from '@ui/gql-client';

const ICONS = {
  home: <Iconify icon="ic:baseline-space-dashboard" />,
  connections: <Iconify icon="icon-park-solid:connection-point-two" />,
  monitor: <Iconify icon="bxs:camera-home" />,
  table: <Iconify icon="bx:data" />,
  view: <Iconify icon="fluent:database-window-20-regular" />,
  biartifact: <Iconify icon="material-symbols:auto-graph-rounded" />,
  topquestions: <Iconify icon="mdi:question-mark-circle" />,
  usage: <Iconify icon="tabler:cloud-dollar" />,
  feed: <Iconify icon="solar:feed-bold" />,
  dbt: <Iconify icon="simple-icons:dbt" />,
  settings: <Iconify icon={'mdi:settings'} />,
  warehouses: SnowflakeWarehouseSVG,
  tags: TagsNavIconSVG,
};

enum NavType {
  FEED = 'Feed',
  TOP_QUESTIONS = 'Top Questions',
  MONITORS = 'Monitors',
  TABLES = 'Data',
  VIEWS = 'Views',
  BI_ARTIFACTS = 'BI Artifacts',
  DBT = 'Dbt',
  USAGE = 'Usage',
  CONNECTIONS = 'Connections',
  WAREHOUSES = 'Warehouses',
  TAGS = 'Tags',
}

enum SettingsNavType {
  SETTINGS = 'Settings',
}

const navItems: { [key in NavType]: NavListProps } = {
  [NavType.FEED]: {
    title: NavType.FEED,
    path: '/feed',
    icon: ICONS.feed,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeViewFeed,
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.TOP_QUESTIONS]: {
    title: NavType.TOP_QUESTIONS,
    path: '/topquestions',
    icon: ICONS.topquestions,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.MONITORS]: {
    title: NavType.MONITORS,
    path: '/monitors',
    icon: ICONS.monitor,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
    isCustomPrivilegesAllowingAccess: true,
  },
  [NavType.TABLES]: {
    title: NavType.TABLES,
    path: '/tables',
    icon: ICONS.table,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
    isCustomPrivilegesAllowingAccess: true,
  },
  [NavType.VIEWS]: {
    title: NavType.VIEWS,
    path: '/views',
    icon: ICONS.view,
    dataSourceTypes: [
      DataSourceType.DataSourceTypeSnowflake,
      DataSourceType.DataSourceTypeBigquery,
    ],
    featureFlag: 'enableSnowflakeViews',
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.WAREHOUSES]: {
    title: NavType.WAREHOUSES,
    path: '/warehouses',
    icon: ICONS.warehouses,
    dataSourceTypes: [DataSourceType.DataSourceTypeSnowflake],
    featureFlag: 'snowflakeWarehouseManagement',
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.BI_ARTIFACTS]: {
    title: NavType.BI_ARTIFACTS,
    path: '/biartifacts',
    icon: ICONS.biartifact,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
    isCustomPrivilegesAllowingAccess: true,
  },
  [NavType.DBT]: {
    title: NavType.DBT,
    path: '/dbt',
    icon: ICONS.dbt,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeViewDbt,
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.USAGE]: {
    title: NavType.USAGE,
    path: '/usage',
    icon: ICONS.usage,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
      GlobalPrivilege.GlobalPrivilegeFinOps,
    ],
  },
  [NavType.TAGS]: {
    title: NavType.TAGS,
    path: '/tags',
    icon: ICONS.tags,
    featureFlag: 'showTagsInNavBar',
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
  [NavType.CONNECTIONS]: {
    title: NavType.CONNECTIONS,
    path: '/connections',
    icon: ICONS.connections,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
  },
};

const navItemsForDbt = [NavType.FEED, NavType.DBT, NavType.CONNECTIONS];
const navItemsForDataQualityOnlyTenant = [
  NavType.FEED,
  NavType.MONITORS,
  NavType.TABLES,
  NavType.CONNECTIONS,
];
const navItemsAll = Object.keys(navItems) as NavType[];
const settingNavItems: NavListProps[] = [
  {
    title: SettingsNavType.SETTINGS,
    path: '/settings',
    icon: ICONS.settings,
    requiredGlobalPrivileges: [
      GlobalPrivilege.GlobalPrivilegeAdmin,
      GlobalPrivilege.GlobalPrivilegeEditAll,
      GlobalPrivilege.GlobalPrivilegeViewAll,
    ],
    isCustomPrivilegesAllowingAccess: true,
  },
];

const buildNavConfig = (
  list: NavType[],
  currentDataSourceType?: DataSourceType
): {
  subheader: string;
  items: NavListProps[];
}[] => {
  const config = [
    {
      subheader: '',
      items: list.reduce((acc, navItem) => {
        const item = navItems[navItem];
        if (
          !item.dataSourceTypes ||
          item.dataSourceTypes.some((d) => d === currentDataSourceType)
        ) {
          acc.push(item);
        }
        return acc;
      }, [] as NavListProps[]),
    },
    {
      subheader: '',
      items: settingNavItems,
    },
  ];
  return config;
};

export const getNavConfigByTenantType = (
  tenantType: TenantType | undefined,
  tenantSubtype: TenantSubtype | undefined | null,
  currentDataSourceType?: DataSourceType,
  isDataQualityOnlyTenant?: boolean
) => {
  if (
    tenantType === TenantType.TenantTypeTeam &&
    tenantSubtype === TenantSubtype.TenantSubtypeDbt
  ) {
    return buildNavConfig(navItemsForDbt);
  }

  if (isDataQualityOnlyTenant) {
    return buildNavConfig(
      navItemsForDataQualityOnlyTenant,
      currentDataSourceType
    );
  }

  return buildNavConfig(navItemsAll, currentDataSourceType);
};

export const footerLinks = [
  {
    name: 'Terms of Use',
    href: 'https://revefi.com/tos',
  },
  {
    name: 'Privacy Policy',
    href: 'https://revefi.com/privacy',
  },
];
