import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Iconify, commonPalette } from '..';

interface BasicErrorMessageProps {
  message: string;
  sx?: SxProps<Theme>;
}

const BasicErrorMessage = ({ message, sx }: BasicErrorMessageProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      color={commonPalette.error.main}
      mb={2}
      sx={sx}
    >
      <Typography variant="body2" display="flex" alignItems="center">
        <Iconify icon="ic:round-error" height={18} width={18} mr={1} />
      </Typography>
      <Typography variant="body2" lineHeight={'18px'}>
        {message}
      </Typography>
    </Box>
  );
};

export default BasicErrorMessage;
