import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingScreen } from '@ui/components';
import Login from '../routes/auth';
import { useAuth } from '@ui/components';
import TenantGuard from './TenantGuard';
// components

// ----------------------------------------------------------------------

type AuthenticationGuardProps = {
  children: ReactNode;
};

function isVerifiedUser(user: any) {
  // waad -> Windows Azure Active Directory (aka Azure AD) user
  const isWaadSub = user?.sub?.startsWith('waad|');

  const isWaadUser = isWaadSub == undefined ? false : isWaadSub;

  // for Azure AD users, Auth0 will not have email_verified to true.
  // Allow users with waad authentication sign-in without the email_verified set to true.
  return isWaadUser || user?.email_verified;
}

export default function AuthenticationGuard({
  children,
}: AuthenticationGuardProps) {
  const { isLoading, isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (isAuthenticated && !isVerifiedUser(user)) {
    return <Navigate to="/error/verify" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} replace />;
  }

  return <TenantGuard>{children}</TenantGuard>;
}
