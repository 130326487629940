import * as React from 'react';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSettings } from '@ui/components';
import { Typography } from '@mui/material';

const buttonStyles = {
  width: '52px',
  margin: '0px',
  borderRadius: '0px !important',
};

export function ThemeToggle() {
  const { themeMode, onChangeMode, isSystemThemeModeSelected } = useSettings();
  const onModeSelect = (
    event: React.MouseEvent<HTMLElement>,
    themeMode: string | null
  ) => {
    if (themeMode !== null) {
      onChangeMode(themeMode);
    }
  };

  return (
    <ToggleButtonGroup
      value={isSystemThemeModeSelected ? 'system' : themeMode}
      exclusive
      onChange={onModeSelect}
      aria-label="theme toggle button group"
      sx={{ display: 'flex', height: '32px' }}
    >
      <ToggleButton value="light" aria-label="Light theme" style={buttonStyles}>
        <Typography variant="button">Light</Typography>
      </ToggleButton>
      <ToggleButton value="dark" aria-label="Dark theme" style={buttonStyles}>
        <Typography variant="button">Dark</Typography>
      </ToggleButton>
      <ToggleButton
        value="system"
        aria-label="System based theme"
        style={buttonStyles}
      >
        <Typography variant="button">Auto</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
