import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, IconButton, PopoverOrigin } from '@mui/material';
import MenuPopover, { Arrow } from './MenuPopover';
import Iconify from './Iconify';

interface MenuOptionsProps {
  menuOptions: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  arrow?: string;
  menuIcon?: React.ReactNode;
  text?: string;
}

const MenuOptionsCard = forwardRef<{ closeMenu: () => void }, MenuOptionsProps>(
  (props, ref) => {
    const {
      menuOptions,
      anchorOrigin = { vertical: 'top', horizontal: 'left' },
      transformOrigin = { vertical: 'top', horizontal: 'right' },
      arrow = 'right-top',
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      closeMenu: handleClose,
    }));

    return (
      <>
        {props.text ? (
          <Button
            onClick={handleOpen}
            aria-label="menu"
            aria-controls={open ? 'menu-options' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            startIcon={props.menuIcon}
            variant="outlined"
          >
            {props.text}
          </Button>
        ) : (
          <IconButton
            onClick={handleOpen}
            aria-label="menu"
            aria-controls={open ? 'menu-options' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
          >
            {props.menuIcon ? (
              props.menuIcon
            ) : (
              <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            )}
          </IconButton>
        )}
        <MenuPopover
          id="menu-options"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          arrow={arrow as Arrow}
          sx={{
            mt: -1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
            '& svg': { mr: 2, width: 24, height: 24 },
          }}
        >
          {menuOptions}
        </MenuPopover>
      </>
    );
  }
);

export default MenuOptionsCard;
