import { ElementType, lazy, Suspense } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { LoadingScreen } from '@ui/components';
import HomeLayout from '../app/layouts/home';
import LogoOnlyLayout from '../app/layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../app/guards/AuthGuard';
import { ErrorBoundary } from 'react-error-boundary';
import NavGuard from '../app/guards/NavGuard';
import Incident from '../app/routes/incident/Incident';
import ConditionalUnexpectedError from '../app/routes/error/ConditionalUnexpectedError';
import { useAtom } from 'jotai';
import { currentDataSourceAtom, isDbtOnlyTenantAtom } from '@ui/states';
import { DataSourceType } from '@ui/gql-client';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const Loadable = (Component: ElementType) =>
  function (props: object) {
    return (
      <ErrorBoundary fallback={<ConditionalUnexpectedError />}>
        <Suspense fallback={<LoadingScreen />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };

const useGetDataSourceSpecificRoutes = () => {
  const [currentDataSource] = useAtom(currentDataSourceAtom);
  const currentDataSourceType: DataSourceType =
    currentDataSource?.type ?? DataSourceType.DataSourceTypeUnspecified;

  const routes: RouteObject[] = [];
  if (currentDataSourceType === DataSourceType.DataSourceTypeSnowflake) {
    routes.push({ path: '/warehouses/*', element: <Warehouses /> });
    routes.push({ path: '/tasks/*', element: <Tasks /> });
  }
  return routes;
};

const useGetFeatureFlagControlledRoutes = () => {
  const featureFlagControlledRoutes: RouteObject[] = [];
  const featureFlagPaths = [
    {
      isEnabled: useFeatureFlagEnabled('enableUserManagement') ?? false,
      path: '/account/*',
      element: <RevefiAccountDetails />,
    },
  ];

  for (let i = 0; i < featureFlagPaths.length; i++) {
    const { isEnabled, path, element } = featureFlagPaths[i];
    if (isEnabled) {
      featureFlagControlledRoutes.push({
        path,
        element,
      });
    }
  }
  return featureFlagControlledRoutes;
};

export default function Router() {
  const [isDbtOnlyTenant] = useAtom(isDbtOnlyTenantAtom);
  const routesChildren: RouteObject[] = [
    { path: '/feed/*', element: <Feed /> },
    { path: '/dbt/*', element: <Dbt /> },
    { path: '/connections', element: <Connections /> },
    { path: '/settings/*', element: <Settings /> },
  ];

  if (!isDbtOnlyTenant) {
    routesChildren.push(
      { path: '/*', element: <Monitor /> },
      { path: '/tables/*', element: <Table /> },
      { path: '/views/*', element: <View /> },
      { path: '/biartifacts/*', element: <BIArtifacts /> },
      { path: '/topquestions/*', element: <TopQuestions /> },
      { path: '/usage/*', element: <Usage /> },
      { path: '/tags/*', element: <Tags /> },
      { path: '/incident/*', element: <Incident /> }
    );
  }

  // Add data source specific routes
  const dataSourceSpecificRoutes = useGetDataSourceSpecificRoutes();
  routesChildren.push(...dataSourceSpecificRoutes);

  // Add feature flag specific routes
  const featureFlagControlledRoutes = useGetFeatureFlagControlledRoutes();
  routesChildren.push(...featureFlagControlledRoutes);

  let effectiveTenantRoutes = routesChildren;

  const isOnlyDataQualityTenant = useFeatureFlagEnabled(
    'isOnlyDataQualityTenant'
  );
  if (isOnlyDataQualityTenant) {
    effectiveTenantRoutes = [
      { path: '/*', element: <Monitor /> },
      { path: '/feed/*', element: <Feed /> },
      { path: '/tables/*', element: <Table /> },
      { path: '/connections', element: <Connections /> },
      { path: '/settings/*', element: <Settings /> },
      /*
       * At the time of writing this comment a "Data Quality Only Tenant" un-bundling or
       * gate-keeping is only available for snowflake data source. Thus, it is fine to add /tasks
       * route without checking the data source type.
       * Furthermore, due to time-crunch we are choosing to have this route enabled for all data
       * sources instead of refactoring this code. We will refactor this code soon.
       */
      { path: '/tasks/*', element: <Tasks /> },
      /*
       * We only want to enable the /usage/queries/:queryHash for data quality only tenant right
       * now. Thus, although, we are adding the /usage to the routes, the corresponding router will
       * only add /queries/:queryHash for data quality only tenant
       */
      { path: '/usage/*', element: <Usage /> },
    ];
  }

  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <NavGuard>
            <HomeLayout />
          </NavGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: isDbtOnlyTenant ? (
            <Navigate to="/dbt" replace />
          ) : (
            <Navigate to="/tables" replace />
          ),
          index: true,
        },
        ...effectiveTenantRoutes,
      ],
    },
    {
      path: '/error',
      element: <LogoOnlyLayout />,
      children: [{ path: '/error/*', element: <ErrorAccess /> }],
    },
    {
      path: '/maintenance/*',
      element: <LogoOnlyLayout />,
      children: [{ path: '/maintenance/*', element: <Maintenance /> }],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
    },
    {
      path: '/onboarding/*',
      element: (
        <AuthGuard>
          <NavGuard>
            <Onboarding />
          </NavGuard>
        </AuthGuard>
      ),
    },
    {
      path: '/signup/*',
      element: (
        <AuthGuard>
          <Signup />
        </AuthGuard>
      ),
    },
  ]);
}

const Monitor = Loadable(lazy(() => import('../app/routes/monitor/router')));

const Table = Loadable(lazy(() => import('../app/routes/tables/router')));

const View = Loadable(lazy(() => import('../app/routes/views/router')));

const BIArtifacts = Loadable(
  lazy(() => import('../app/routes/biartifacts/router'))
);

const TopQuestions = Loadable(
  lazy(() => import('../app/routes/topquestions/router'))
);

const Usage = Loadable(lazy(() => import('../app/routes/usage/router')));

const Feed = Loadable(lazy(() => import('../app/routes/feed')));

const Dbt = Loadable(lazy(() => import('../app/routes/dbt/router')));

const Connections = Loadable(lazy(() => import('../app/routes/connections')));

const ErrorAccess = Loadable(lazy(() => import('../app/routes/error/router')));

const Maintenance = Loadable(
  lazy(() => import('../app/routes/maintenance/router'))
);

const Onboarding = Loadable(
  lazy(() => import('../app/routes/onboarding/router'))
);

const Signup = Loadable(lazy(() => import('../app/routes/signup/router')));

const Settings = Loadable(lazy(() => import('../app/routes/settings/router')));

const Warehouses = Loadable(
  lazy(() => import('../app/routes/warehouses/router'))
);

const Tags = Loadable(lazy(() => import('../app/routes/tags/router')));

const Tasks = Loadable(lazy(() => import('../app/routes/tasks/router')));

const RevefiAccountDetails = Loadable(
  lazy(() => import('../app/routes/account/router'))
);
