// @mui
import { Grid } from '@mui/material';

import { SummaryCard } from '@ui/components';
import { useGetIncidentListQuery } from '@ui/gql-client';
// components
import { useState, useEffect } from 'react';

export default function SummaryTopCards() {
  const [incidentSummaryData, setIncidentSummaryData] = useState<any>();

  const { data, loading, error, fetchMore } = useGetIncidentListQuery();

  useEffect(() => {
    if (!loading && !incidentSummaryData && data && data.incidents) {
      const summaryData = [
        {
          label: 'Opened',
          value: data.incidents?.length,
        },
        {
          label: 'Active',
          value: data.incidents?.filter(
            (element) => element.status === 'Active'
          )?.length,
        },
        {
          label: 'Closed',
          value: data.incidents?.filter(
            (element) => element.status === 'Closed'
          )?.length,
        },
      ];
      setIncidentSummaryData(summaryData);
    }
  }, [data, loading, incidentSummaryData]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {loading || !incidentSummaryData ? (
          <p>Loading...</p>
        ) : (
          <SummaryCard title="Incidents" data={incidentSummaryData} />
        )}
      </Grid>
    </Grid>
  );
}
