/**
 * @fileoverview App-wide state management for connections (data sources, integrations).
 * Created Oct 2, 2023
 */

import { BiObjectType, DataSourceType } from '@ui/gql-client';
import { atom } from 'jotai';
import _uniq from 'lodash/uniq';

/* Data source connection states */

export interface DataSourceItem {
  id: number;
  type: DataSourceType;
  name: string;
  bigQueryMultiProjectEnabled?: boolean | null;
}

export const dataSourcesAtom = atom<DataSourceItem[]>([]);

export const hasDataSourceAtom = atom((get) => get(dataSourcesAtom).length > 0);

export const currentDataSourceIdAtom = atom<number | null>(null);

export const currentDataSourceAtom = atom<DataSourceItem | undefined>((get) =>
  get(dataSourcesAtom).find((ds) => ds.id === get(currentDataSourceIdAtom))
);

/* BigQuery project and region states */

// one to many relationship between project and regions (a project can belong to multiple regions)
export const bigQueryRegionsByProjectAtom = atom<Map<string, string[]>>(
  new Map()
);

// one to many relationship between region and projects (a region can have multiple projects)
export const bigQueryProjectsByRegionAtom = atom<Map<string, string[]>>(
  new Map()
);

export const bigQueryAllProjectIdsAtom = atom((get) =>
  Array.from(get(bigQueryRegionsByProjectAtom).keys())
);

export const bigQueryAllRegionsAtom = atom<string[]>((get) =>
  _uniq(Array.from(get(bigQueryRegionsByProjectAtom).values()).flat())
);

/* Dbt connection states */

export const hasDbtIntegrationAtom = atom(false);
export const hasDbtProjectAtom = atom(false);

export const currentDbtProjectIdAtom = atom<number | null>(null);

/* Business intelligence connection states */
export interface BiConnection {
  type: BiObjectType;
  url?: string;
}

export const biConnectionsAtom = atom<BiConnection[]>([]);
