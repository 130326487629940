// @mui
import {
  Stack,
  Dialog,
  Typography,
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@mui/material';
// components

import { Iconify, Scrollbar, useAuth } from '@ui/components';

import HubSpotForm from './HubSpotForm';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function HubSpotDialog({ open, onClose }: Props) {
  const { user } = useAuth();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        We are happy to help!
        <IconButton
          aria-label="close dialog"
          sx={{
            position: 'absolute',
            right: 12,
            top: 16,
          }}
          onClick={onClose}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pt: 2.5, px: 3 }}
        >
          <Typography variant="subtitle2">
            {' '}
            Please fill out the form below and we'll get back to you as soon as
            possible.{' '}
          </Typography>
        </Stack>
        <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 365 }}>
          <Box sx={{ m: 2 }}>
            <HubSpotForm
              portalId="24281241"
              formId="e50c6dbd-3497-4ade-a5e1-28fc26465067"
              email={user?.email}
            />
          </Box>
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
