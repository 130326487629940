import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Stack } from '@mui/material';
// hooks
// config
// components
import {
  cssStyles,
  Logo,
  NAVBAR,
  NavSectionVertical,
  Scrollbar,
  useCollapseDrawer,
  useResponsive,
  useSettings,
} from '@ui/components';
// config
import { getNavConfigByTenantType } from './NavConfig';
import CollapseButton from './CollapseButton';
import SwitchToDemoTenant from '../../../components/SwitchToDemoTenant';

import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { useAtom } from 'jotai';
import { currentDataSourceAtom, tenantTypeSubtypeAtom } from '@ui/states';
import { DataSourceType } from '@ui/gql-client';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const theme = useTheme();

  const { themeMode } = useSettings();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const showDemoDataButton = useFeatureFlagEnabled('showDemoDataButton');

  const enableSessionRecordings = useFeatureFlagEnabled(
    'enableSessionRecordings'
  );

  const isOnlyDataQualityTenant = useFeatureFlagEnabled(
    'isOnlyDataQualityTenant'
  );

  const posthog = usePostHog();

  const [{ tenantType, tenantSubtype }] = useAtom(tenantTypeSubtypeAtom);

  const [currentDataSource] = useAtom(currentDataSourceAtom);
  const currentDataSourceType: DataSourceType =
    currentDataSource?.type ?? DataSourceType.DataSourceTypeUnspecified;

  const navConfig = getNavConfigByTenantType(
    tenantType,
    tenantSubtype,
    currentDataSourceType,
    isOnlyDataQualityTenant
  );

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    posthog && enableSessionRecordings && posthog.startSessionRecording();
  }, [enableSessionRecordings, posthog]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          textAlign="center"
          justifyContent="space-between"
        >
          <Logo themeMode={themeMode} isCollapsed={isCollapse} />
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      {showDemoDataButton && <SwitchToDemoTenant />}
      <Box flexGrow={1}></Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'solid',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
