import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Operation,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { setContext } from '@apollo/client/link/context';

import { useAuth0 } from '@auth0/auth0-react';

import { useEffect, useMemo, useState } from 'react';

/**
 * Transforms the data from cubeJs.
 * 1. Removes schema name from the key
 * 2. Converts the data vale to a number if not a number
 * @param data
 */

interface Extensions {
  code?: string;
  exception?: {
    code?: string;
  };
}

const mayBeMapToNumber = (value: any) => {
  /*
   * The `getGridColumnsData` sets the type values in the items object as number. However, this may
   * or may not be true. It depends on the dimension/measure type defined in the corresponding Cube
   * schema. To auto-cast a dimension/measure value to string or number, it utilizes `isNaN` method
   * and converts all non NaN values to Number.
   * i.e.
   * - "a" will be kept as is
   * - "1.6" will ke converted to Number("1.6")
   * - "000123" will be converted to Number("000123")
   *
   * As seen from the third example above, a string representation of a number with leading zeros
   * is thus converted to a number. This causes an issue especially when fetching error_code column
   * from Snowflake Task History. The error_code is stored as a varchar and can have leading zeros.
   * The auto-casting mistakenly converts this string to a number and thus, when the auto-casted
   * value is supplied back to snowflake as a filter in one of the cubes instead of matching
   * "000123", the cube tries to filter for Number(123). Thus, incorrect rows a returned from
   * Snowflake.
   *
   * To overcome this, this method only maps a value to number if the value does not start with
   * leading zeros.
   *
   * The given value can either be of type string or number.
   * When the value is a number, by definition, it cannot have leading zeros, thus, no processing is
   * required.
   * When the value is a string, the method checks for leading zeros, if found returns the value as
   * string else maps it to number.
   */
  const isString = typeof value === 'string' || value instanceof String;
  return isString && value.startsWith('0') ? value : Number(value);
};

export const getGridColumnsData = (data: any) => {
  if (data && data.data) {
    return data.data.map((items: { [x: string]: number }) => {
      const gridItem: any = {};
      for (const item in items) {
        const key = item.split('.')[1];
        gridItem[key] = !isNaN(items[item])
          ? mayBeMapToNumber(items[item])
          : items[item];
      }
      return gridItem;
    });
  }
  return [];
};

interface Props {
  children?: any;
  showUnauthorizedError?: (message: string) => void;
}

// these operations should not be batched as we want to allow
// these operations irrespective of roles assigned to the user
// we can't guard partial operations in an http request
const operationsNotToBeBatched = [
  'getCurrentUserRoleDetails',
  'GetDataSourceConnectionStatus',
  'GetDataSourceType',
  'GetAllConfig',
  'getTenantInfoForUser',
  'GetDataSourceConnectionStatus',
  'getAllTenantInfosForUser',
  'getCurrentUserPrivileges',
  'getUserToDemoTenantMapping',
  'GetDbtConnections',
  'GetDbtProjects',
  'getAllDemoTenantMappings',
  'getWarehousesUtilizationInfo',
];

const splitTest = (operation: Operation) => {
  const { operationName } = operation;
  return !operationsNotToBeBatched.includes(operationName);
};

export default function AuthorizedApolloProvider({
  showUnauthorizedError,
  children,
}: Props) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [authToken, setAuthToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      const getAccessToken = async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: process.env['NX_AUTH0_AUDIENCE'] as string,
            claim: 'all',
          });
          setAuthToken(token);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      getAccessToken();
    } else {
      setAuthToken(null);

      setIsLoading(false);
    }
  }, [isAuthenticated]);

  const apolloClient = useMemo(() => {
    const authMiddleware = setContext(async (_, { headers, ...context }) => {
      const urlParams = new URLSearchParams(window.location.search);

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${authToken}`,
          datasourceid: urlParams.get('dsId'),
        },
        ...context,
      };
    });

    const uri = process.env['NX_GQL_ENDPOINT_CHROME'] ?? '/query';
    const batchHttpLink = new BatchHttpLink({
      uri,
      batchMax: 3,
      batchInterval: 20,
    });
    const httpLink = new HttpLink({
      uri,
    });
    return new ApolloClient({
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
      },
      connectToDevTools: true,
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(
              ({
                message,
                extensions,
              }: {
                message: string;
                extensions?: Extensions;
              }) => {
                if (
                  extensions?.code === 'READ_ONLY_TENANT' &&
                  showUnauthorizedError
                ) {
                  showUnauthorizedError(message);
                } else if (
                  extensions?.exception?.code === 'invalid_token' ||
                  extensions?.code === 'invalid_token'
                ) {
                  window.location.replace('/');
                }
              }
            );
          }
          if (networkError) {
            console.error(`[Network error]:`, networkError);
          }
        }),
        authMiddleware,
        new RetryLink().split(splitTest, batchHttpLink, httpLink),
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          ArtifactInformation: {
            merge: true,
          },
          AggTableAccessInfo: {
            merge: true,
          },
          AggDownstreamTableAccessInfo: {
            merge: true,
          },
          DbTableMetadata: {
            merge: true,
          },
          DbtRun: {
            merge: true,
          },
          Feed: {
            merge: true,
            fields: {
              itemsList: {
                keyArgs: ['tagFilters', 'notificationChannelFilter'],
                merge(existing = [], incoming, { variables }) {
                  if (variables?.offset === 0) return incoming;
                  return [...existing, ...incoming];
                },
              },
            },
          },
          FeedTags: {
            keyFields: [],
          },
          Query: {
            fields: {
              dbTableMetadata: {
                merge(existing: [], incoming: any) {
                  return { ...existing, ...incoming };
                },
              },
              CubeData: {
                merge(existing: [], incoming: any) {
                  return {
                    ...incoming,
                    data: {
                      error: incoming.data.error,
                      results: incoming.data.results || [],
                      gridColumnData: incoming.data.results
                        ? getGridColumnsData(incoming.data.results[0])
                        : [],
                    },
                  };
                },
              },
            },
          },
          dbTableMetadata: {
            keyFields: ['databaseName', 'schemaName', 'tableName'],
          },
          WarehouseDetails: {
            keyFields: ['warehouseName'],
          },
          NotificationConfiguration: {
            keyFields: ['notificationConfigurationId'],
          },
        },
      }),
    });
  }, [authToken]);

  if (isLoading || (isAuthenticated && !authToken)) {
    return <div>Loading...</div>;
  }

  if (!apolloClient) {
    return <div>Error: Unable to retrieve authentication token.</div>;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
