import UnexpectedError from './UnexpectedError';
import { Navigate } from 'react-router-dom';
import { MaintenanceStatus, useMaintenanceStatus } from '@ui/components';

export default function ConditionalUnexpectedError() {
  const maintenanceStatus = useMaintenanceStatus();
  if (maintenanceStatus === MaintenanceStatus.IN_PROGRESS) {
    /*
     * We purposefully use a <Navigate> component instead of <MaintanenceInProgress> to ensure
     * the URL is updated to /maintenance.
     *
     * If we return <MaintanenceInProgress>, the URL will not be updated to /maintenance.
     */
    return <Navigate to="/maintenance" replace />;
  }

  return <UnexpectedError />;
}
