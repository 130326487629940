import { useContext } from 'react';
//
import { Auth0Context } from '@auth0/auth0-react';

const useAuth = () => {
  const context = useContext(Auth0Context);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
