import { Box, Container, Grid } from '@mui/material';

import { useSettings } from '@ui/components';

import SummaryTopCards from '../components/SummaryTopCards';
import IncidentDetails from '../components/IncidentDetails';

function IncidentDashboard() {
  const { themeStretch } = useSettings();
  return (
    <Box>
      <Box>
        <Grid item xs={12} md={4}>
          <SummaryTopCards />
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid item xs={12} md={5}>
          <IncidentDetails />
        </Grid>
      </Box>
    </Box>
  );
}

export default IncidentDashboard;
