import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import { Logo } from '@ui/components';
import { Box } from '@mui/material';
import DashboardHeader from './home/header';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout(props: any) {
  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Box>
        <DashboardHeader
          onOpenSidebar={() => true}
          showDataSourceDropdown={false}
        />
      </Box>
      <Outlet />
    </>
  );
}
