import { useState } from 'react';
// @mui
import {
  Stack,
  Dialog,
  DialogActions,
  Button,
  Typography,
  ListItemButton,
  Box,
} from '@mui/material';
// components

import {
  Iconify,
  Scrollbar,
  getIconByConnectionType,
  useUrlParamContext,
  dataSourceTypeToLabelMap,
} from '@ui/components';
import { useAtom } from 'jotai';
import { DataSourceItem, dataSourcesAtom } from '@ui/states';
import { useNavigate } from 'react-router-dom';
type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export default function DataSourceSelectDialog({ open, onClose }: Props) {
  const { updateUrlParam } = useUrlParamContext();
  const navigate = useNavigate();

  const [selectedDataSource, setSelectedDataSource] = useState<
    DataSourceItem | undefined
  >();
  const [dataSourcesList] = useAtom(dataSourcesAtom);

  const handleListItemClick = (dataSource: DataSourceItem) => {
    setSelectedDataSource(dataSource);
  };
  const handleChangeSource = () => {
    selectedDataSource && updateUrlParam(selectedDataSource.id.toString());
    onClose();
    navigate(0);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => {
        setSelectedDataSource(undefined);
        onClose();
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2.5, px: 3 }}
      >
        <Typography variant="subtitle1">
          Select Data Source Connection
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2.5, px: 3 }}
      >
        <Typography variant="caption">
          Choose one of your data connections as the default data connection
          where applicable.
        </Typography>
      </Stack>
      <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
        <Typography variant="subtitle1" sx={{ mt: 2, px: 3 }}>
          Connections
        </Typography>
        <Box sx={{ mt: 2 }}>
          {dataSourcesList.map((dataSource, index: number) => {
            return (
              <ListItemButton
                key={index}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  '&.Mui-selected': {
                    bgcolor: 'action.selected',
                    '&:hover': {
                      bgcolor: 'action.selected',
                    },
                  },
                }}
                selected={
                  selectedDataSource
                    ? selectedDataSource.id === dataSource.id
                    : false
                }
                onClick={() => {
                  handleListItemClick(dataSource);
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Box sx={{ color: 'text.secondary' }} p={1}>
                    {getIconByConnectionType(
                      dataSourceTypeToLabelMap[dataSource.type] as string,
                      '35px'
                    )}
                  </Box>
                  <Box p={0.5}>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      {dataSourceTypeToLabelMap[dataSource.type]}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.primary' }}
                    >
                      {dataSource.name}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemButton>
            );
          })}
        </Box>
      </Scrollbar>
      <DialogActions disableSpacing sx={{ mt: 1 }}>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          size="medium"
          onClick={() => {
            setSelectedDataSource(undefined);
            onClose();
          }}
        >
          Cancel
        </Button>
        <Box sx={{ ml: 2, position: 'relative' }}>
          <Button
            type="submit"
            form="alation"
            variant="contained"
            size="medium"
            disabled={!selectedDataSource}
            onClick={handleChangeSource}
          >
            Change source
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
