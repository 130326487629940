import {
  Typography,
  Button,
  Box,
  MenuItem,
  Menu,
  CircularProgress,
} from '@mui/material';
import {
  useGetUserToDemoTenantMappingQuery,
  useClearUserToDemoTenantMappingMutation,
  useGetAllDemoTenantMappingsLazyQuery,
  useSetUserToDemoTenantMappingMutation,
  DemoTenantType,
} from '@ui/gql-client';

import { MouseEvent, useEffect, useState } from 'react';

import { triggerUserPilotIdentify } from '@ui/components';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export default function SwitchToDemoTenant() {
  const [demoTenantTypeList, setDemoTenantTypeList] = useState(
    [] as DemoTenantType[]
  );

  const [demoMode, setDemoMode] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: userToDemoMappingData, loading: userToDemoMappingLoading } =
    useGetUserToDemoTenantMappingQuery();

  const [
    clearUserToDemoTenantMappingMutation,
    { data: clearData, loading: clearUserToDemoTenantMappingLoading },
  ] = useClearUserToDemoTenantMappingMutation({
    variables: {},
  });

  const [
    setUserToDemoTenantMappingMutation,
    { data: setUserToDemoTenantData, loading: setUserToDemoTenantLoading },
  ] = useSetUserToDemoTenantMappingMutation();

  const [getAllDemoTenantMappings] = useGetAllDemoTenantMappingsLazyQuery({
    variables: {},
    onCompleted: (data) => {
      if (
        data &&
        data.AllDemoTenantMappings &&
        data.AllDemoTenantMappings.types
      ) {
        setDemoTenantTypeList(data.AllDemoTenantMappings.types);
      }
    },
  });

  useEffect(() => {
    if (userToDemoMappingLoading) return;
    const isDemoMode =
      userToDemoMappingData?.UserToDemoTenantMapping as boolean;
    setDemoMode(isDemoMode);
    triggerUserPilotIdentify({
      demoMode: isDemoMode,
    });
    if (!userToDemoMappingData?.UserToDemoTenantMapping) {
      getAllDemoTenantMappings();
    }
  }, [userToDemoMappingData]);

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    type: DemoTenantType
  ) => {
    setUserToDemoTenantMappingMutation({
      variables: {
        demoTenantType: type,
      },
    });
  };

  if (setUserToDemoTenantData || (!userToDemoMappingLoading && clearData)) {
    window.location.replace('/');
  }

  const handleExitDemo = () => {
    clearUserToDemoTenantMappingMutation();
  };

  const client = useApolloClient();
  const navigate = useNavigate();

  if (!userToDemoMappingLoading && clearData) {
    client.resetStore().then(() => {
      navigate('/');
    });
  }

  if (
    userToDemoMappingLoading ||
    setUserToDemoTenantLoading ||
    clearUserToDemoTenantMappingLoading
  ) {
    return (
      <CircularProgress
        size={24}
        sx={{
          color: 'grey.500',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    );
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!demoMode && (
        <Box p={3}>
          <Button
            id="demo-button"
            variant="outlined"
            aria-controls={menuOpen ? 'demo-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            Switch to demo
          </Button>
          <Menu
            id="demo-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'demo-button',
            }}
          >
            {demoTenantTypeList.map((option) => (
              <MenuItem
                key={option}
                onClick={(event) => {
                  handleMenuItemClick(event, option);
                  handleClose();
                }}
              >
                <Typography
                  component="div"
                  sx={{ textTransform: 'capitalize', m: 0.5 }}
                >
                  {option.toLocaleLowerCase().replace('demo_tenant_type_', '')}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      {demoMode && (
        <>
          <Box p={3}>
            <Button
              variant="outlined"
              onClick={handleExitDemo}
              sx={{ background: '#fda92d', color: 'black' }}
            >
              Exit from demo
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
