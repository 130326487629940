import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  Iconify,
  getIconByConnectionType,
  dataSourceTypeToLabelMap,
} from '@ui/components';
import DataSourceSelectDialog from './DataSourceSelectDialog';
import { useEffect, useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  useGetDataSourceConnectionStatusQuery,
  ConnectionStatusType,
  DataSourceType,
} from '@ui/gql-client';
import { useApolloClient } from '@apollo/client';
import { GET_MONITOR_TABLES_FILTERED } from '../../routes/monitor/queries/MonitorTableFiltered';
import { MONITOR_SUMMARY_CARD } from '../../routes/monitor/queries/MonitorSummaryCards';
import { currentDataSourceAtom, hasDataSourceAtom } from '@ui/states';
import { useAtom } from 'jotai';

const getEmptyDataSource = () => {
  return (
    <Box padding={1.5}>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
          You do not have any Data Sources connected.
        </Typography>
        <Box
          component={RouterLink}
          to={'/connections'}
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Click here
        </Box>
        <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
          to get started!
        </Typography>
      </Stack>
    </Box>
  );
};

const connectionStatusMap: {
  [key in ConnectionStatusType]: { icon: string; label: string };
} = {
  [ConnectionStatusType.Connected]: {
    icon: 'material-symbols:check-circle-rounded',
    label: 'Connected',
  },
  [ConnectionStatusType.InProgress]: {
    icon: 'ion:sync',
    label: 'Sync in progress',
  },
  [ConnectionStatusType.Unknown]: {
    icon: 'ion:warning-outline',
    label: 'Sync failure(s)',
  },
};

export default function MultiDataSourceDropdown() {
  const theme = useTheme();

  const showMultipleDSDropdown = useFeatureFlagEnabled(
    'showMultipleDSDropdown'
  );

  const [hasDataSourceConnection] = useAtom(hasDataSourceAtom);
  const [currentDataSource] = useAtom(currentDataSourceAtom);

  const [open, setOpen] = useState(false);

  const handleDropdownChange = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [existingStatus, setExistingStatus] = useState<ConnectionStatusType>();

  const {
    data: connectionStatusData,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useGetDataSourceConnectionStatusQuery({
    fetchPolicy: 'network-only',
  });

  const client = useApolloClient();

  useEffect(() => {
    const currentStatus =
      connectionStatusData?.getDataSourceConnectionStatus?.status;
    if (!existingStatus && currentStatus === ConnectionStatusType.InProgress) {
      startPolling(30000);
    }
    if (
      currentStatus === ConnectionStatusType.Connected &&
      existingStatus === ConnectionStatusType.InProgress
    ) {
      client.refetchQueries({
        include: [GET_MONITOR_TABLES_FILTERED, MONITOR_SUMMARY_CARD],
      });
      stopPolling();
    }
    if (currentStatus) {
      setExistingStatus(currentStatus);
    }
  }, [connectionStatusData?.getDataSourceConnectionStatus]);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.neutral,
        boxShadow: 0,
        borderRadius: 2,
        p: 1,
        width: '97%',
        height: '67px',
      }}
    >
      {hasDataSourceConnection && currentDataSource ? (
        <>
          <Stack direction="row" spacing={2}>
            <Box sx={{ color: 'text.secondary' }} p={1}>
              {getIconByConnectionType(
                dataSourceTypeToLabelMap[currentDataSource.type] as string,
                '35px'
              )}
            </Box>
            <Box p={0.5}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {dataSourceTypeToLabelMap[currentDataSource.type]}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {currentDataSource.name}
              </Typography>
            </Box>
            {existingStatus ? (
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Iconify
                  icon={connectionStatusMap[existingStatus]?.icon}
                  color="primary.main"
                  width={32}
                  borderLeft="1px solid"
                  borderColor="divider"
                  height={32}
                  paddingLeft={1}
                />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {connectionStatusMap[existingStatus]?.label}
                </Typography>
              </Stack>
            ) : null}
            <Box p={2} sx={{ flexGrow: 1 }}>
              {showMultipleDSDropdown && (
                <Stack
                  direction="row"
                  sx={{ float: 'right', cursor: 'pointer' }}
                  onClick={handleDropdownChange}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: 'primary.main', mt: '3px', mr: '3px' }}
                  >
                    Change source
                  </Typography>
                  <Typography color={theme.palette.grey[900]}>
                    <Iconify
                      icon={'eva:arrow-ios-downward-outline'}
                      width={25}
                      height={25}
                    />
                  </Typography>
                </Stack>
              )}
            </Box>
          </Stack>
          <DataSourceSelectDialog open={open} onClose={handleClose} />
        </>
      ) : (
        getEmptyDataSource()
      )}
    </Box>
  );
}
