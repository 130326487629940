/**
 * @fileoverview to navigate the user to right place
 * Created on 13/09/23
 */
import { ReactNode } from 'react';
import {
  TenantSubtype,
  TenantType,
  useGetDataSourceTypeQuery,
  useGetDbtConnectionsQuery,
  useGetDbtProjectsQuery,
} from '@ui/gql-client';
import _isEmpty from 'lodash/isEmpty';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LoadingScreen } from '@ui/components';
import { useAtom, useSetAtom } from 'jotai';
import {
  hasDbtIntegrationAtom,
  hasDbtProjectAtom,
  tenantTypeSubtypeAtom,
} from '@ui/states';

interface NavGuardProps {
  children: ReactNode;
}
const NavGuard = ({ children }: NavGuardProps) => {
  const { data, loading, error } = useGetDataSourceTypeQuery();
  const {
    data: dbtData,
    loading: dbtLoading,
    error: dbtError,
  } = useGetDbtConnectionsQuery();

  const { data: dbtProjectData, loading: dbtProjectLoading } =
    useGetDbtProjectsQuery();
  const { pathname } = useLocation();
  const [{ tenantType, tenantSubtype }] = useAtom(tenantTypeSubtypeAtom);
  const setHasDbtIntegration = useSetAtom(hasDbtIntegrationAtom);
  const setHasDbtProject = useSetAtom(hasDbtProjectAtom);

  if (loading || dbtLoading || dbtProjectLoading) {
    return <LoadingScreen />;
  }
  if (dbtData) {
    setHasDbtIntegration(!_isEmpty(dbtData?.getExternalIntegration));
  }
  if (dbtProjectData) {
    setHasDbtProject(!_isEmpty(dbtProjectData?.DbtProjects));
  }
  //TODO(@joyce-revefi): add error handling

  const isOnboardingPath = pathname.includes('/onboarding');
  const isConfigurationsEmpty = _isEmpty(data?.Configurations);
  const isDbtConnectionsEmpty =
    _isEmpty(dbtData?.getExternalIntegration) &&
    _isEmpty(dbtProjectData?.DbtProjects);

  // For enterprise and team tenants (non-dbt), no configurations => onboarding path
  if (
    tenantType === TenantType.TenantTypeEnterprise ||
    (tenantType === TenantType.TenantTypeTeam &&
      tenantSubtype !== TenantSubtype.TenantSubtypeDbt)
  ) {
    // If there are valid configurations, should not take user to onboarding path
    if (!isConfigurationsEmpty && pathname.includes('/onboarding/welcome')) {
      return <Navigate to="/" />;
    }

    // If no configuration found, navigate the user to the onboarding page if not already
    if (isConfigurationsEmpty && !isOnboardingPath) {
      return <Navigate to="/onboarding/welcome" replace />;
    }
  }

  // // For team-dbt tenants, no dbt connections => onboarding path, ok to have no configurations
  if (
    tenantType === TenantType.TenantTypeTeam &&
    tenantSubtype === TenantSubtype.TenantSubtypeDbt
  ) {
    // If there are dbt connections, should not take user to onboarding path
    if (!isDbtConnectionsEmpty && pathname.includes('/onboarding/welcome')) {
      return <Navigate to="/" />;
    }

    // If no dbt connections found, navigate the user to the onboarding page if not already
    if (isDbtConnectionsEmpty && !isOnboardingPath) {
      return <Navigate to="/onboarding/welcome" replace />;
    }
  }

  return <>{children}</>;
};

export default NavGuard;
