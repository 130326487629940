// @mui
import {
  Card,
  Box,
  CardHeader,
  Typography,
  Stack,
  CardProps,
  Tooltip,
} from '@mui/material';
// utils

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  data: {
    label?: string | null | undefined;
    value?: number | 0 | string | undefined;
    percentage?: number | 0 | undefined;
    total?: number | 0 | undefined;
  }[];
}

export default function SummaryCard({
  title,
  subheader,
  data,
  ...other
}: Props) {
  return (
    <Card
      sx={{
        padding: 3,
        minHeight: '160px',
        minWidth: '320px',
      }}
      {...other}
    >
      <CardHeader sx={{ padding: 0 }} title={title} subheader={subheader} />
      <Box dir="ltr" sx={{ marginTop: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          {data.map((row) =>
            row.percentage && row.total ? (
              <Tooltip
                key={row.label}
                title={`${row.percentage}% of ${row.total}`}
              >
                <Stack alignItems="center">
                  <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.secondary' }}
                      minHeight={'22px'}
                    >
                      {row.label}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" sx={{ mb: 0 }}>
                    <Typography variant="h6">
                      {row.value ? row.value.toLocaleString() : 0}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" sx={{ mb: 0 }}>
                    {row.percentage !== null && row.percentage !== undefined && (
                      <Typography
                        variant="subtitle2"
                        sx={{ color: 'text.disabled' }}
                        minHeight={'9px'}
                      >
                        {row.percentage}%
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Tooltip>
            ) : (
              <Stack key={row.label} alignItems="center">
                <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.secondary' }}
                    minHeight={'22px'}
                  >
                    {row.label}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ mb: 0 }}>
                  <Typography variant="h6">
                    {row.value ? row.value.toLocaleString() : 0}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ mb: 0 }}>
                  {row.percentage !== null && row.percentage !== undefined && (
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.disabled' }}
                      minHeight={'9px'}
                    >
                      {row.percentage}%
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      </Box>
    </Card>
  );
}
