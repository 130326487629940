// see - https://docs.datadoghq.com/real_user_monitoring/browser/

import { datadogRum } from '@datadog/browser-rum';

interface DatadogClient {
  enabled: boolean;
  datadogRum: any;
}

export const initializeDatadog = (): DatadogClient => {
  const datadog: DatadogClient = {
    enabled: false,
    datadogRum: null,
  };

  // no need to enable datadog for local build.
  if (process.env.DATADOG_ENABLED && process.env.DATADOG_ENABLED == 'true') {
    // do not init if both DATADOG_APP_ID and DATADOG_CLIENT_TOKEN is not defined.
    if (process.env.DATADOG_APP_ID && process.env.DATADOG_CLIENT_TOKEN) {

      // session replay sample rate is 0 for prod.
      let sessionReplaySampleRate = 0;

      if (process.env.APP_ENVIRONMENT && process.env.APP_ENVIRONMENT != "aws-prod") {
        sessionReplaySampleRate = 100;
      }

      datadogRum.init({
        applicationId: process.env.DATADOG_APP_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us3.datadoghq.com',
        service: process.env.APP_ENVIRONMENT,
        env: process.env.APP_ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.TAG_RELEASE,
        sessionSampleRate: 100,
        sessionReplaySampleRate: sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadog.enabled = true;
      datadog.datadogRum = datadogRum;
    }
  }

  return datadog;
};

export default initializeDatadog;
