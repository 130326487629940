/**
 * @fileoverview Wraps a component with a switch to demo tenant functionality
 * Created on 15/09/23
 */
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import {
  useSetUserToDemoTenantMappingMutation,
  useGetUserToDemoTenantMappingQuery,
  useGetAllDemoTenantMappingsLazyQuery,
  useClearUserToDemoTenantMappingMutation,
} from '@ui/gql-client';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useApolloClient } from '@apollo/client';

interface SwitchToDemoPropProps {
  switchToDemo: () => void;
  isDemoMode: boolean;
  exitDemo: () => void;
}
export default function withSwitchToDemoProp<P>(
  Component: React.ComponentType<P & SwitchToDemoPropProps>
) {
  return (props: P) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const client = useApolloClient();

    const { data: userToDemoMappingData, loading: userToDemoMappingLoading } =
      useGetUserToDemoTenantMappingQuery();

    const [
      setUserToDemoTenantMappingMutation,
      { data: setUserToDemoTenantData, loading: setUserToDemoTenantLoading },
    ] = useSetUserToDemoTenantMappingMutation();

    const [getAllDemoTenantMappings, { loading: getAllDemoTenantLoading }] =
      useGetAllDemoTenantMappingsLazyQuery({
        variables: {},
        onCompleted: (data) => {
          if (
            data &&
            data.AllDemoTenantMappings &&
            data.AllDemoTenantMappings.types
          ) {
            setUserToDemoTenantMappingMutation({
              variables: {
                demoTenantType: data.AllDemoTenantMappings.types[0],
              },
            });
            window.location.replace('/');
          }
        },
      });

    const [
      clearUserToDemoTenantMappingMutation,
      { data: clearData, loading: clearUserToDemoTenantMappingLoading },
    ] = useClearUserToDemoTenantMappingMutation({
      variables: {},
    });

    const exitDemo = useCallback(() => {
      clearUserToDemoTenantMappingMutation();
    }, []);

    const switchToDemo = useCallback(() => {
      getAllDemoTenantMappings();
    }, []);

    useEffect(() => {
      if (setUserToDemoTenantData || (!userToDemoMappingLoading && clearData)) {
        window.location.replace('/');
      }
    }, [setUserToDemoTenantData, clearData, userToDemoMappingLoading]);

    const isLoading =
      userToDemoMappingLoading ||
      setUserToDemoTenantLoading ||
      getAllDemoTenantLoading ||
      clearUserToDemoTenantMappingLoading;

    return isLoading ? (
      <Box sx={{ width: '100%', height: '100%', position: 'absolute' }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: palette.background.neutral,
            opacity: 0.5,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
          }}
        >
          <CircularProgress size={32} />
        </Box>
      </Box>
    ) : (
      <Component
        {...props}
        switchToDemo={switchToDemo}
        isDemoMode={
          !clearData && !!userToDemoMappingData?.UserToDemoTenantMapping
        }
        exitDemo={exitDemo}
      />
    );
  };
}
