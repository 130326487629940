//
import Image from '../../Image';
//
import { CustomFile } from '../types';
import FileThumbnail from '../../file-thumbnail';
import { Stack, Typography } from '@mui/material';
import { fData, fileData, varFade } from '@ui/components';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }
  const { key, name = '', size = 0 } = fileData(file);
  const imgUrl = typeof file === 'string' ? file : file.preview;

  return (
    <Stack
      component={m.div}
      {...varFade().inUp}
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        my: 1,
        px: 1,
        py: 0.75,
      }}
    >
      <FileThumbnail
        tooltip
        imageView
        file={file}
        sx={{ position: 'absolute' }}
        imgSx={{ position: 'absolute' }}
      />
      <Stack flexGrow={1} sx={{ minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fData(size)}
        </Typography>
      </Stack>
    </Stack>
  );
}
