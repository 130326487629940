import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import React from 'react';
import { ContentCopy } from '@mui/icons-material';
import _noop from 'lodash/noop';
import Iconify from '../Iconify';

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

type AccordionWrapperProps = {
  text?: string;
  textComponent?: React.ReactNode;
  title: string;
  allowCopy?: boolean;
  infoTooltipText?: string;
  defaultExpanded?: boolean;
};

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  boxShadow: 'none',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  title,
  text,
  textComponent,
  allowCopy = true,
  infoTooltipText,
  defaultExpanded = false,
}) => {
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <CustomAccordion
      defaultExpanded={defaultExpanded}
      disableGutters
      style={{ boxShadow: 'none' }}
      sx={{ boxShadow: 'none' }}
    >
      <CustomAccordionSummary>
        <Typography variant="subtitle1">{title}</Typography>

        {infoTooltipText && (
          <Tooltip
            title={<Typography>{infoTooltipText}</Typography>}
            placement="right"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  minWidth: 250,
                },
                '& .MuiTypography-root': {
                  fontSize: '1.2em',
                  fontWeight: 500,
                },
              },
            }}
          >
            <IconButton onClick={_noop} sx={{ ml: '4px', p: '4px' }}>
              <Iconify
                icon={'eva:info-fill'}
                sx={{
                  color: 'text.secondary',
                  width: 16,
                  height: 16,
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </CustomAccordionSummary>
      <CustomAccordionDetails
        sx={{
          maxHeight: '375px',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        {text && (
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
            }}
            variant="body2"
          >
            {text}
          </Typography>
        )}
        <Box>{textComponent}</Box>
        {allowCopy && text && (
          <AccordionActions sx={{ position: 'sticky', bottom: 0, right: 0 }}>
            <Button
              variant="contained"
              startIcon={<ContentCopy />}
              color="inherit"
              onClick={() => handleCopy(text)}
            >
              Copy
            </Button>
          </AccordionActions>
        )}
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};
