import { Container, Typography } from '@mui/material';
import { Page } from '@ui/components';
import { useSettings } from '@ui/components';

import IncidentDashboard from './sections/IncidentDashboard';
import {
  ExternalIntegration,
  useGetExternalIntegrationsQuery,
} from '@ui/gql-client';
import { useEffect, useState } from 'react';

function Incident() {
  const { themeStretch } = useSettings();

  const {
    data: extIntgData,
    loading: extIntgLoading,
    error: extIntgError,
  } = useGetExternalIntegrationsQuery();

  const extIntgList =
    (extIntgData?.getExternalIntegration as ExternalIntegration[]) ?? [];

  const [jiraConnection, setJiraConnection] = useState(true);

  useEffect(() => {
    if (!extIntgLoading) {
      // filter JiraIntegration
      const jiraList = extIntgList.filter((item) => item.type === 'Jira');

      if (jiraList && jiraList.length == 0) {
        setJiraConnection(false);
      }
    }
  }, [extIntgData]);

  return (
    <Page title="Revefi: Incidents">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {jiraConnection ? (
          <IncidentDashboard />
        ) : (
          <Typography variant="subtitle2" color="text.secondary">
            You haven't added Jira integration! Please connect to Jira to view
            incidents.
          </Typography>
        )}
      </Container>
    </Page>
  );
}

export default Incident;
