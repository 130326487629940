/**
 * @fileoverview to retrieve tenant info for the user, and redirect to the appropriate page
 * Created on September 19, 2023
 */

import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LoadingScreen, setGAUser } from '@ui/components';
import { useAuth } from '@ui/components';
import {
  RoleUserAssociationType,
  useGetTenantInfoForUserQuery,
  useUpdateAssignedRolesMutation,
} from '@ui/gql-client';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { useSetAtom } from 'jotai';
import { tenantInfoAtom, userInfoAtom } from '@ui/states';
import { datadog } from '../../main';

type TenantGuardProps = {
  children: ReactNode;
};

const ADMIN_ROLE_ID = 1;

/**
 * Assume that user is already authenticated
 * If user is not authenticated, Auth Guard will redirect to login page
 * */
const TenantGuard = ({ children }: TenantGuardProps) => {
  const { user } = useAuth();
  const posthog = usePostHog();
  const { pathname, search } = useLocation();

  const userVars = {
    userId: user?.sub ?? '',
    email: user?.email ?? '',
  };

  const { data, loading, error } = useGetTenantInfoForUserQuery({
    variables: userVars,
  });

  const tenantInfo = data?.TenantInfoForUser.tenantInfo;
  const setUserInfo = useSetAtom(userInfoAtom);
  const setTenantInfo = useSetAtom(tenantInfoAtom);

  const [updateAssignedRoles] = useUpdateAssignedRolesMutation();

  const isUserManagementFeatureEnabled =
    useFeatureFlagEnabled('enableUserManagement') ?? false;

  useEffect(() => {
    // FIXME(@joyce-revefi): need to check if tenant info is current tenant, but currently not supported by backend
    if (tenantInfo) {
      setGAUser({
        revefiUserId: data?.TenantInfoForUser?.revefiUserId ?? '',
        tenantId: tenantInfo.tenantId,
        tenantName: tenantInfo.tenantName ?? '',
      });

      if (user?.sub) {
        posthog?.identify(user?.sub, {
          revefiUserId: data?.TenantInfoForUser?.revefiUserId,
          tenantId: tenantInfo.tenantId.toString() ?? '',
          tenantName: tenantInfo.tenantName ?? '',
        });
      }

      if (datadog.enabled) {
        datadog.datadogRum.setUser({
          id: data?.TenantInfoForUser?.revefiUserId,
          tenantId: tenantInfo.tenantId.toString() ?? '',
        });
      }

      setTenantInfo(tenantInfo);
    }
    if (data) {
      setUserInfo({
        userId: user?.sub ?? '',
        userEmail: user?.email ?? '',
        revefiUserId: data?.TenantInfoForUser.revefiUserId ?? '',
        userNotAuthorized: data.TenantInfoForUser.userNotAuthorized,
        noTenantPresent: data.TenantInfoForUser.noTenantPresent,
        userNotAuthorizedReason: data.TenantInfoForUser.userNotAuthorizedReason,
      });
    }

    // assign admin role to admin
    if (
      isUserManagementFeatureEnabled &&
      data?.TenantInfoForUser.revefiUserId &&
      tenantInfo?.adminEmail === user?.email
    ) {
      updateAssignedRoles({
        variables: {
          revefiUserIds: [data.TenantInfoForUser.revefiUserId],
          roleIds: [ADMIN_ROLE_ID],
          roleUserAssociationTypes: [
            RoleUserAssociationType.RoleUserAssociationTypeOwner,
          ],
        },
      });
    }
  }, [data, user, posthog]);

  if (loading) {
    return <LoadingScreen />;
  }

  if ((!data || !data?.TenantInfoForUser) && pathname !== '/error/unexpected') {
    return <Navigate to={`/error/unexpected${search}`}></Navigate>;
  }

  if (
    data?.TenantInfoForUser.userNotAuthorized &&
    pathname !== '/error/restricted'
  ) {
    return <Navigate to={`/error/restricted${search}`}></Navigate>;
  }

  if (data?.TenantInfoForUser.noTenantPresent && pathname !== '/signup') {
    return <Navigate to={`/signup${search}`}></Navigate>;
  }

  return <>{children}</>;
};

export default TenantGuard;
