import Router from '../router';
import {
  ThemeProvider,
  initializeGA,
  UrlParamProvider,
  AppContextProvider,
} from '@ui/components';
import { useNavigate } from 'react-router-dom';
import {
  ScrollToTop,
  ProgressBarStyle,
  MotionLazyContainer,
} from '@ui/components';
import { AuthorizedApolloProvider } from '@ui/gql-client';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_API } from '@ui/components';
import { LicenseInfo } from '@mui/x-license-pro';
import { PostHogProvider } from 'posthog-js/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Suspense, useState } from 'react';

//MUI DataGrid Pro License
LicenseInfo.setLicenseKey(
  '73df2f7e915fd719aaa4c452a53ab8f1Tz04NTk0NixFPTE3NDE0MjEyMTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

initializeGA();

const options = {
  api_host: process.env['NX_REACT_APP_POSTHOG_HOST'],
  session_recording: {
    maskAllInputs: true,
  },
  disable_session_recording: true,
  cookie_expiration: 14 /* 14 days */,
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: { returnTo: string }) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export function App() {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function showError(message: string): void {
    setOpen(true);
    setErrorMessage(message);
  }

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Suspense fallback={null}>
      <Auth0ProviderWithRedirectCallback
        domain={AUTH0_API.domain as string}
        clientId={AUTH0_API.clientId as string}
        redirectUri={AUTH0_API.redirectUri as string}
        claim="all"
        audience={AUTH0_API.audience}
      >
        <PostHogProvider
          apiKey={process.env['NX_REACT_APP_POSTHOG_KEY']}
          options={options}
        >
          <AuthorizedApolloProvider showUnauthorizedError={showError}>
            <MotionLazyContainer>
              <ThemeProvider>
                <ProgressBarStyle />
                <ScrollToTop />
                <AppContextProvider>
                  <UrlParamProvider>
                    <Router />
                  </UrlParamProvider>
                </AppContextProvider>
              </ThemeProvider>
            </MotionLazyContainer>
          </AuthorizedApolloProvider>
        </PostHogProvider>
      </Auth0ProviderWithRedirectCallback>
      <ThemeProvider>
        <Dialog open={open} onClose={onClose}>
          <DialogContent sx={{ mt: 3 }}>
            <Typography
              paragraph
              variant="subtitle1"
              sx={{ color: 'text.primary' }}
            >
              You're in read-only mode. This action is not allowed.
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', paddingTop: 0 }}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="medium"
              onClick={onClose}
            >
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
