// importing Alation logo as SVG

const AlationSVG = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 350 150.7"
    xmlSpace="preserve"
  >
    <path
      d="M338.7.9L225 46.1l-43.4 17.3v-.1c-1.7.8-3.6 1.3-5.7 1.3-2.8 0-5.5-.9-7.6-2.4l-42.5-28.7L88.1 8.1c-5.7-3.9-13-2-16.1 4.1L1.3 148.5c-3.2 6.1-.5 9 5.9 6.5l118.5-47 39.6-15.7c1.5-.6 3-.9 4.7-.9 2.9 0 5.5.9 7.7 2.5l47.3 31.9 32.8 22.1c5.7 3.9 13 2 16.1-4.1L344.6 7.4c3.1-6.1.5-9-5.9-6.5M135.5 81.6l-9.8 3.9L51.5 115c-3.1 1.2-6.9 2.7-8 1.6-1.2-1.2.5-5 2.1-8.1l34.2-65.9c1.6-3 3.9-5.9 6.8-6.7 3-.8 6.5.7 9.4 2.6l29.8 20.1 11.9 8.1c2.1 1.6 6.4 4.5 6.4 7.3-.1 3.8-5.4 6.4-8.6 7.6M225 68.5l69.5-27.6c.5-.2.9-.3 1.4-.4 2.9-1.1 6-2.2 7-1.2 1.1 1.2-.5 5-2.1 8.1l-34.2 65.9c-1.6 3-3.9 6-6.7 6.7-2.2.6-4.6 0-6.9-1.2-1-.3-1.9-.7-2.9-1.3l-25-16.9-13.4-9.1c-.4-.4-5.8-4.3-6.1-4.8-1.6-1.4-3.1-3.2-3.1-4.8 0-2.9 3.3-5.2 6.2-6.6l16.3-6.8z"
      fill="#f37121"
    />
    {/* SVG with Alation name commented out below */}
    {/* <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 907 155.9"> */}
    {/* <path d="M711.2 36c0-1.2-.9-2.1-2.1-2.1h-8c-4.2 0-7.7 3.5-7.7 7.7v8c0 1.1.9 2.1 2.1 2.1h8c4.3 0 7.7-3.5 7.7-7.7v-8zM509.3 2h-7.9c-4.3 0-7.7 3.5-7.7 7.7V152c0 1.2.9 2.1 2.1 2.1h7.9c4.2 0 7.7-3.5 7.7-7.7V4.1c0-1.1-.9-2.1-2.1-2.1M709 66.4h-7.9c-4.2 0-7.7 3.5-7.7 7.7V152c0 1.2.9 2.1 2.1 2.1h7.9c4.2 0 7.7-3.4 7.7-7.7v-78c0-1.1-1-2-2.1-2M475 145c-.1-.5-.2-.9-.3-1.3L437.5 5.9c-.5-2.2-2.4-3.9-4.8-3.9-1.8 0-3.4 1-4.3 2.5l-76.5 145.6-.4.7c-.1.3-.2.6-.2 1 0 1.2 1 2.1 2.1 2.1h11.8c3.1 0 5.8-1.8 7-4.5l13.5-25.8c1.5-3 4.5-6.5 10.7-6.5h43.4c3.5 0 8.5 2 10.4 7.3l6.9 27.8c.2 1 1 1.7 2.1 1.7h8.3c4.3 0 7.7-3.4 7.7-7.7-.1-.3-.1-.7-.2-1.2m-32.3-50.9c.4 1.5.6 5.5-3.4 5.5h-34.9c-4.3 0-4-4.1-3-5.9l25-48c1.3-2.4 3.5-3.4 4.5.4l11.8 48z"/> */}
    {/* <path d="M768.7 63.6c-25.5 0-41.7 20.7-41.7 46.1s16.2 46.1 41.7 46.1c25.5 0 41.7-20.7 41.7-46.1s-16.2-46.1-41.7-46.1zm0 75.1c-15.2 0-23.7-13-23.7-29s8.6-29 23.7-29c15.2 0 23.7 13 23.7 29s-8.6 29-23.7 29zM675.1 147.4l-1.2-2.5c-2.3-4.6-7.3-7.2-12.3-6.2-7.3 1.4-14.2.4-19-3.5-4.2-3.4-5.7-7.1-5.7-18.4V85.3c0-1.2 1-2.2 2.2-2.2H663c6.6 0 12-5.4 12-12v-3c0-1.1-.9-1.9-1.9-1.9H639c-1.1 0-2-.9-2-2V36c0-1.3-1-2.3-2.3-2.3h-7.6c-4.3 0-7.7 3.5-7.7 7.7v75.2c0 15.3 2.8 26.6 13.8 33.5 6.3 3.9 16.1 5.5 23.9 5.5 5.8 0 11.6-1.4 16.1-3.1 1.9-.5 2.9-3 1.9-5.1zM602.6 104.3v42.3c0 4.1-3.3 7.5-7.5 7.5h-8c-1.2 0-2.1-1-2.1-2.1v-5.5c0-.6-.7-.9-1.1-.5-5.6 5.7-14.3 9.9-22.2 9.9-10.7 0-19.1-2.4-26.1-9.5-4.8-4.9-7.3-11.5-7.3-18.9 0-13.9 11-22.3 20.7-26.2 8.7-3.4 21-5.3 34-6 1 0 1.8-.8 1.7-1.8 0-3.6-.5-8-5.8-11.7-7.3-5.1-16.6-4-27.1.3-5.1 2.1-10.4.6-14.4-3.5-.4-.4-1-1-1.6-1.7-1.5-1.6-1.3-4.3.5-5.5 16.5-10.9 40.6-9.9 51.9-3 12.2 7.4 14.4 19.3 14.4 35.9zm-17.5 10.8V112c0-1-.8-1.8-1.8-1.8-9.5.1-20.5 1.3-26.7 3.8-5.6 2.3-10.7 6.6-10.7 13.2 0 8.2 6.8 13.1 15.1 13.2 5 0 12.2-2.3 17.9-8.8 3.4-3.9 6.2-8.5 6.2-16.5zM869.2 63.6c-9.7 0-17.6 3.8-24.4 9.4-.5.4-1.3.1-1.3-.5v-4.1c0-1.2-.9-2.1-2.1-2.1h-7.9c-4.2 0-7.7 3.4-7.7 7.7v78c0 1.2.9 2.1 2.1 2.1h7.9c4.2 0 7.7-3.4 7.7-7.7v-42.5c0-10.7 7.7-23.1 23.9-23.1 15.2.1 21.9 9.6 21.9 21.9V152c0 1.2.9 2.1 2.1 2.1h7.9c4.2 0 7.7-3.4 7.7-7.7v-44.3c0-25.8-17.7-38.5-37.8-38.5z"/> */}
  </svg>
);

export default AlationSVG;
