import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface UrlParamContextType {
  urlParam: string | null;
  updateUrlParam: (newParam: string) => void;
}

const UrlParamContext = createContext<UrlParamContextType | undefined>(
  undefined
);

function useUrlParamContext() {
  const context = useContext(UrlParamContext);
  if (!context) {
    throw new Error(
      'useUrlParamContext must be used within a UrlParamProvider'
    );
  }
  return context;
}

function UrlParamProvider({ children }: { children: React.ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialUrlParam = searchParams.get('dsId') || '0';
  const [urlParam, setUrlParam] = useState<string | null>(initialUrlParam);

  const updateUrlParam = (newParam: string) => {
    setUrlParam(newParam);
    setSearchParams(
      (prev) => {
        prev.set('dsId', newParam);
        return prev;
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (searchParams.get('dsId') !== urlParam) {
      updateUrlParam(urlParam as string);
    }
  }, [urlParam, searchParams]);

  return (
    <UrlParamContext.Provider value={{ urlParam, updateUrlParam }}>
      {children}
    </UrlParamContext.Provider>
  );
}

export { UrlParamProvider, useUrlParamContext };
