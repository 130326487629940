import { useState } from 'react';
import { useAuth, Label } from '@ui/components';

// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from '@mui/material';
// components
import { Iconify, IconButtonAnimate, MenuPopover } from '@ui/components';
import HubSpotDialog from './HubSpotDialog';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: '/',
  },
];

// ----------------------------------------------------------------------

interface SupportPopoverProps {
  version: string;
}

export default function SupportPopover({ version }: SupportPopoverProps) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { user, logout } = useAuth();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const [showHubSpotForm, setShowHubSpotForm] = useState(false);

  const handleClose = () => {
    setOpen(null);
    setShowHubSpotForm(false);
  };

  const handleSupportTicketClick = () => {
    setShowHubSpotForm(true);
  };
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.8),
            },
          }),
        }}
      >
        <Iconify icon="formkit:help" height="36px" width="36px" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handleSupportTicketClick} sx={{ m: 1 }}>
          <Iconify
            icon="streamline:interface-help-customer-support-1-customer-headset-help-microphone-phone-support"
            height="15px"
            width="15px"
            marginRight={1}
          />
          Create Support Ticket
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => window.open('https://docs.revefi.com', '_blank')}
          sx={{ m: 1 }}
        >
          <Iconify
            icon="radix-icons:open-in-new-window"
            height="15px"
            width="15px"
            marginRight={1}
          />
          View Revefi Docs
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }}>
          <Label sx={{ fontSize: '10px' }}>{version}</Label>
        </MenuItem>
      </MenuPopover>
      <HubSpotDialog open={showHubSpotForm} onClose={handleClose} />
    </>
  );
}
