import { gql } from '@apollo/client';

export const GET_MONITOR_TABLES_FILTERED = gql`
  query GetDqChecksFilteredWithCount($filters: ComplexQueryFilterSortInput) {
    DqChecksFilteredWithCount(filters: $filters) {
      count
      DqChecks {
        id
        artifactId
        userManaged
        sqlMetricParamsList {
          displayName
          value
        }
        metricEnum
        metricLabel
        metric {
          sqlMetric {
            userSpecifiedName
          }
          catalogCheckMetric {
            userSpecifiedName
          }
        }
        metricEvaluation {
          id
          dqCheckId
          tenantId
          artifactId
          latestStatus
          dqCheckId
          latestCollectionPeriodUtc {
            seconds
          }
          dqCheckEvaluationList {
            callerJobId
            minThreshold
            maxThreshold
            value
            collectionPeriodDate
            collectionPeriodUtc {
              seconds
            }
            checkPassed
            useForTraining
            continuationOfPreviousFailure
            suppressedFailure
          }
          sparkLine
        }
        artifact {
          id
          type
          tenantId
          dataSourceId
          dbTableMetadata {
            schemaName
            databaseName
            tableName
          }
          info {
            artifactId
            totalRowCount
            lastUpdatedTimeUtc {
              seconds
            }
            lastUpdatedDate
          }
          isFavorite
          isExcluded
        }
      }
    }
  }
`;
