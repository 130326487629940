import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardHeader,
  CardProps,
  Stack,
  Typography,
} from '@mui/material';
// utils
import { fFloorNumber } from '@ui/components';

// components
import { BaseOptionChart } from '@ui/components';
import { ApexOptions } from 'apexcharts';
// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;
const LEGEND_HEIGHT = 125;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    overflow: 'scroll',
    paddingTop: '35px',
    paddingBottom: '5px',
  },
  '& .apexcharts-legend-series': {
    width: '46%;',
  },
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chartColors: string[];
  extendedChartOptions?: ApexOptions;
  chartData: {
    label: string;
    value: number;
  }[];
}

export default function PieChart({
  title,
  subheader,
  chartColors,
  chartData,
  extendedChartOptions,
  ...other
}: Props) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fFloorNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  if (extendedChartOptions) {
    merge(chartOptions, extendedChartOptions);
  }

  if (chartData.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={315}
      >
        <Stack>
          <Typography textAlign="center">No data available</Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <ChartWrapperStyle>
      <ReactApexChart
        type="pie"
        series={chartSeries}
        options={chartOptions}
        height={280}
      />
    </ChartWrapperStyle>
  );
}
