import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Collapse } from '@mui/material';
// type
import { NavListProps } from '../type';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import {
  GlobalPrivilege,
  RoleDetails,
  useGetCurrentUserRoleDetailsQuery,
} from '@ui/gql-client';
import { useFeatureFlagEnabled } from 'posthog-js/react';

// ----------------------------------------------------------------------

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
};

const isCurrentNavItemVisible = (
  list: NavListProps,
  currentUserRoleDetails: RoleDetails[]
) => {
  // if there are no restrictions
  if (!list.requiredGlobalPrivileges?.length) {
    return true;
  }

  // if there are restrictions
  const { requiredGlobalPrivileges, isCustomPrivilegesAllowingAccess } = list;

  return requiredGlobalPrivileges.some((requiredPrivilege: GlobalPrivilege) => {
    return currentUserRoleDetails.some((userRole: RoleDetails) => {
      const { customPrivilegesList, globalPrivilegesList } = userRole;
      if (isCustomPrivilegesAllowingAccess && customPrivilegesList?.length) {
        return true;
      }
      return globalPrivilegesList?.includes(requiredPrivilege);
    });
  });
};

export function NavListRoot({ list, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();

  const { data: currentUserData, loading: currentUserDataLoading } =
    useGetCurrentUserRoleDetailsQuery();

  const isUserManagementFeatureEnabled =
    useFeatureFlagEnabled('enableUserManagement') ?? false;

  const currentUserRoleDetails =
    currentUserData?.getCurrentUserDetails.roleDetails;

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (
    isUserManagementFeatureEnabled &&
    (currentUserDataLoading ||
      !currentUserRoleDetails ||
      !isCurrentNavItemVisible(list, currentUserRoleDetails))
  ) {
    return null;
  }

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => (
                <NavListSub key={item.title + item.path} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub
                key={item.title + item.path}
                item={item}
                active={getActive(item.path, pathname)}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
