import { useFeatureFlagEnabled } from 'posthog-js/react';

export enum MaintenanceStatus {
  UNKNOWN,
  IN_PROGRESS,
  DISABLED,
}

const MAINTENANCE_IN_PROGRESS_FLAG_KEY =
  process.env['MAINTENANCE_IN_PROGRESS_FLAG_KEY'];

export default function useMaintenanceStatus(): MaintenanceStatus {
  if (!MAINTENANCE_IN_PROGRESS_FLAG_KEY) {
    console.warn('MAINTENANCE_IN_PROGRESS_FLAG_KEY is not set in env vars');
  }
  const flagKey = MAINTENANCE_IN_PROGRESS_FLAG_KEY || '';
  const flagValue = useFeatureFlagEnabled(flagKey);
  if (flagValue === undefined) {
    return MaintenanceStatus.UNKNOWN;
  } else if (flagValue) {
    return MaintenanceStatus.IN_PROGRESS;
  }
  return MaintenanceStatus.DISABLED;
}
