import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  commonPalette,
  getErrorFromApolloObject,
  Iconify,
  useAuth,
} from '@ui/components';
import {
  TenantInfo,
  TenantSubtype,
  TenantType,
  useAddUsersToTenantMutation,
  useGetAllowedUsersForTenantQuery,
  useRemoveUsersFromTenantMutation,
  useUpdateTenantInfoMutation,
} from '@ui/gql-client';
import React, { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';

interface MembersTabProps {
  currentTenant: TenantInfo;
  isAdmin: boolean;
}

const MembersTab: React.FC<MembersTabProps> = ({ currentTenant, isAdmin }) => {
  const { user } = useAuth();

  const [userEmailsToAdd, setUserEmailsToAdd] = useState<string>('');
  const [existingUsers, setExistingUsers] = useState<string[]>([]);
  const { data, loading, error } = useGetAllowedUsersForTenantQuery({
    variables: {
      callerEmail: user?.email ?? '',
    },
  });
  useEffect(() => {
    if (data?.AllowedUsersForTenant.userEmailsList) {
      setExistingUsers(data.AllowedUsersForTenant.userEmailsList);
    }
  }, [data]);

  const [
    addUsersToTenantMutation,
    { loading: addUsersLoading, error: addUsersError },
  ] = useAddUsersToTenantMutation();
  const handleAddUsers = () => {
    addUsersToTenantMutation({
      variables: {
        callerEmail: user?.email ?? '',
        emails: userEmailsToAdd.split(',').map((email) => email.trim()),
      },
      onCompleted: () => {
        setUserEmailsToAdd('');
      },
      refetchQueries: ['getAllowedUsersForTenant'],
    });
  };

  const [removeUsersFromTenantMutation] = useRemoveUsersFromTenantMutation();
  const handleRemoveUser = (email: string) => {
    removeUsersFromTenantMutation({
      variables: {
        callerEmail: user?.email ?? '',
        emails: [email],
      },
      refetchQueries: ['getAllowedUsersForTenant'],
    });
  };

  const [
    updateTenantInfo,
    { loading: saveUpdateLoading, error: saveUpdateError },
  ] = useUpdateTenantInfoMutation();

  const userVars = {
    userId: user?.sub ?? '',
    email: user?.email ?? '',
  };

  const handleTenantSubTypeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tenantSubType = event.target.checked
      ? TenantSubtype.TenantSubtypeUnspecified
      : TenantSubtype.TenantSubtypeEnterpriseWithInvite;
    updateTenantInfo({
      variables: {
        ...userVars,
        tenantSubtype: tenantSubType,
      },
      refetchQueries: ['getAllTenantInfosForUser', 'getAllowedUsersForTenant'],
    });
  };

  return (
    <>
      <Box
        sx={{
          '& .MuiTextField-root': {
            '& .MuiInputBase-root': {
              backgroundColor: 'background.paper',
              height: 36,
              fontSize: '0.875rem',
            },
          },
          '& .MuiDivider-root': {
            my: 1.75,
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
          Members
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {isAdmin ? 'Manage members here.' : 'Only admins can manage members.'}
        </Typography>
        {isAdmin && (
          <>
            <Divider />
            {(currentTenant.tenantType === TenantType.TenantTypeTeam ||
              currentTenant.tenantSubtype ===
                TenantSubtype.TenantSubtypeEnterpriseWithInvite) && (
              <>
                <Typography
                  variant="body2"
                  fontWeight={700}
                  mt={3}
                  gutterBottom
                >
                  Add team members
                </Typography>
                <Stack direction="row" spacing={1} my={0.5} alignItems="center">
                  <TextField
                    size="small"
                    margin="none"
                    multiline
                    placeholder="Email, comma separated"
                    variant="outlined"
                    value={userEmailsToAdd}
                    onChange={(e) => setUserEmailsToAdd(e.target.value)}
                    sx={{ flexGrow: 1 }}
                  />
                  <Box sx={{ height: 36, position: 'relative' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleAddUsers}
                      disabled={addUsersLoading}
                      sx={{ height: 36 }}
                    >
                      Add members
                    </Button>
                    {addUsersLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: 'grey.500',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>
                </Stack>
                {addUsersError && (
                  <Typography
                    variant="body2"
                    color={commonPalette.error.main}
                    gutterBottom
                    display="flex"
                    alignItems="center"
                  >
                    <Iconify icon="ic:round-error" mr={1} />
                    {getErrorFromApolloObject(addUsersError)}
                  </Typography>
                )}
              </>
            )}
            <Typography variant="body2" fontWeight={700} mt={3} gutterBottom>
              Current members
            </Typography>
            {currentTenant.tenantType === TenantType.TenantTypeEnterprise && (
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          currentTenant.tenantSubtype ===
                          TenantSubtype.TenantSubtypeUnspecified
                        }
                        onChange={handleTenantSubTypeSwitch}
                        name={'allUsers'}
                      />
                    }
                    label={'Allow all users of your domain'}
                  />
                </FormGroup>
              </FormControl>
            )}
            {(currentTenant.tenantType === TenantType.TenantTypeTeam ||
              currentTenant.tenantSubtype ===
                TenantSubtype.TenantSubtypeEnterpriseWithInvite) &&
              (loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'grey.500',
                    margin: 1,
                  }}
                />
              ) : (
                <>
                  {existingUsers.length === 0 ? (
                    <Typography variant="body2">
                      No members. Invite a team member!
                    </Typography>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                      }}
                    >
                      <List>
                        {existingUsers.map((email) => (
                          <ListItem
                            key={email}
                            secondaryAction={
                              isAdmin && (
                                <IconButton
                                  color="error"
                                  onClick={() => handleRemoveUser(email)}
                                >
                                  <Iconify
                                    icon={'ic:round-remove-circle-outline'}
                                    width={20}
                                    height={20}
                                  />
                                </IconButton>
                              )
                            }
                          >
                            <Typography variant="body2" fontWeight={500}>
                              {email}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </>
              ))}
          </>
        )}
      </Box>
    </>
  );
};

export default MembersTab;
