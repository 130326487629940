//adding ts-nocheck because of types error in react-apexCharts https://github.com/apexcharts/react-apexcharts/issues/449
import merge from 'lodash/merge';
import _transform from 'lodash/transform';
import ReactApexChart from 'react-apexcharts';
// @mui
import {
  Card,
  CardHeader,
  Box,
  CardProps,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import { BaseOptionChart } from './chart';
import { useTheme } from '@mui/material/styles';
import Iconify from './Iconify';
import { useEffect, useState } from 'react';
import { fSubDate } from './utils/formatTime';
import {
  ChartToolbarFilters,
  DateFilters,
  dateFiltersConfigMap,
} from './chartToolbarFilters';
// ----------------------------------------------------------------------

/**
 * Gets the updated chart options with the date filter 7D/30D/60D/90D/Auto
 * @param prevOptions options already set on the chart
 * @param dateFilterValue number representing the last number of days. dateFilterValue of 0 means Auto
 */
const dateFilterOptions = [
  DateFilters.Last7Days,
  DateFilters.Last30Days,
  DateFilters.Last90Days,
  DateFilters.Auto,
];

const getUpdatedChartOptions = (
  prevOptions: any,
  dateRange?: [string, string]
) => {
  if (dateRange) {
    //if date filter value is set, use that to se the min and max on x-axis
    return {
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        min: new Date(dateRange[0]).getTime(),
        max: new Date(dateRange[1]).getTime(),
      },
    };
  }
  //else return the min max undefined for autoscaling. Used when 'Auto' selected
  return {
    ...prevOptions,
    xaxis: {
      ...prevOptions.xaxis,
      min: undefined,
      max: undefined,
    },
  };
};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  yAxisTitle?: string;
  chartLabels: string[];
  markerColors: object[];
  showNegativeValuesTooltip: boolean;
  chartData: {
    name: string;
    type: string;
    fill?: string;
    data: any;
  }[];
  showToolbarFilters?: boolean;
  extendChartOptions: any;
  onDateFilterChange?: (dateFilterKey: DateFilters) => void;
}

export default function CardWithRangeAreaChart({
  title,
  subheader,
  yAxisTitle,
  chartLabels,
  chartData,
  markerColors,
  showNegativeValuesTooltip,
  extendChartOptions,
  showToolbarFilters = false,
  onDateFilterChange,
  ...other
}: Props) {
  const theme = useTheme();
  // help tip configuration
  const [helpOpen, setHelpOpen] = useState(false);

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };
  const handleHelpClose = () => {
    setHelpOpen(false);
  };
  const chartOptions = merge(
    BaseOptionChart(),
    {
      colors: [theme.palette.secondary.lighter, theme.palette.success.main],
      fill: { type: chartData.map((i) => i.fill) },
      labels: chartLabels,
      xaxis: {
        type: 'datetime',
      },
      stroke: {
        curve: 'straight',
      },
      legend: {
        show: true,
      },
      markers: {
        size: [0, 5],
        opacity: 1,
        discrete: markerColors,
      },
      tooltip: {
        intersect: false,
        followCursor: true,
        fixed: false,
        custom: ({ dataPointIndex, w }: any) => {
          let alertToolTipMessage = '';
          w.config.markers.discrete.forEach((marker: any) => {
            // marker shape is square for alerts sent.
            // chart doesnt have context of complete data that is returned from server so using marker shape instead.

            if (
              marker.shape === 'square' &&
              marker.dataPointIndex === dataPointIndex
            ) {
              alertToolTipMessage = `
                 <span style='font-size:11px'><i>An alert was generated as data did not land in expected time window</i></span>`;
            }
          });
          //couldnt use MUI components or styles!
          return `
              <div class='tooltipBox' style='padding: 10px;'>
                <div style='margin: 4px;'>
                  <span>
                    ${w.config.series[1].data[dataPointIndex].x}
                  </span>
                </div>
                ${alertToolTipMessage}
                <div style='padding-top:10px; margin: 7px;'>
                <span style='background: ${w.globals.colors[1]}; width:12px; height:12px; border-radius: 50%; display: inline-block;'></span>
                   <span>Current Value</span> : <span>${w.config.series[1].data[dataPointIndex].y}</span>
               </div>
                <div style='margin: 7px;'>
                    <span style='background: ${w.globals.colors[0]}; width:12px; height:12px; border-radius: 50%; display: inline-block;'></span> Min Threshold : 
                    <span>${w.config.series[0].data[dataPointIndex].y[0]}</span>
                </div>
                <div style='margin: 7px;'>
                 <span style='background: ${w.globals.colors[0]}; width:12px; height:12px; border-radius: 50%; display: inline-block;'></span> Max Threshold : 
                    <span>${w.config.series[0].data[dataPointIndex].y[1]}</span>
               </div>
                `;
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          decimalsInFloat: 2,
          title: {
            text: yAxisTitle,
          },
        },
      ],
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
    },
    extendChartOptions
  );

  const [options, setOptions] = useState(chartOptions);
  useEffect(() => {
    setOptions(merge(chartOptions, extendChartOptions));
  }, [extendChartOptions]);

  const [activeButton, setActiveButton] = useState(DateFilters.Auto);

  const handleButtonClick = (key: DateFilters) => {
    if (key !== activeButton) {
      setActiveButton(key);
      setOptions(
        getUpdatedChartOptions(options, dateFiltersConfigMap[key].dateRange)
      );
      onDateFilterChange?.(key);
    }
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 1, pb: 1 }} dir="ltr">
        {chartData[1].data.length ? (
          <>
            {showToolbarFilters ? (
              <ChartToolbarFilters
                activeButton={activeButton}
                dateFilterKeys={dateFilterOptions}
                handleButtonClick={handleButtonClick}
              />
            ) : null}

            <Stack spacing={1} sx={{ mb: 1 }}>
              <ReactApexChart
                type="rangeArea"
                series={chartData}
                options={options}
                height={300}
              />
            </Stack>
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 1, pb: 1 }}
            minHeight={315}
          >
            <Stack>
              <Typography textAlign="center">No data available</Typography>
            </Stack>
          </Box>
        )}
        {showNegativeValuesTooltip && (
          <Stack>
            <Typography fontSize={12} sx={{ color: 'text.secondary' }}>
              Why are we seeing negative values in this chart
              <Iconify
                icon={'eva:question-mark-circle-fill'}
                width={14}
                height={14}
                sx={{
                  p: 0,
                  ml: 1,
                  color: 'text.secondary',
                  cursor: 'pointer',
                  verticalAlign: 'sub',
                }}
                onClick={handleHelpOpen}
              />
            </Typography>
          </Stack>
        )}
      </Box>
      <Dialog open={helpOpen} onClose={handleHelpClose}>
        <DialogTitle>
          Why are we seeing negative values in this chart?
        </DialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <Typography paragraph variant="body2" sx={{ color: 'text.primary' }}>
            Even though cumulative / actual rows added per day are never
            negative, the observed variances at times can lead to negative
            values for thresholds.
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingTop: 0 }}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="medium"
            onClick={handleHelpClose}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
