import { fSubDate, fTruncateHour } from './formatTime';
import { times } from 'lodash';

export const enum DateRange {
  Last24Hours = 'Last 24 Hours',
  Last7Days = 'Last 7 Days',
  Last30Days = 'Last 30 Days',
  Last90Days = 'Last 90 Days',
  Last180Days = 'Last 180 Days',
  Last1Year = 'Last 1 Year',
}

export const dateRangeToTimeRangeMap: { [key in DateRange]: [string, string] } =
  {
    [DateRange.Last24Hours]: [
      fTruncateHour(fSubDate(1)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
    [DateRange.Last7Days]: [
      fTruncateHour(fSubDate(7)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
    [DateRange.Last30Days]: [
      fTruncateHour(fSubDate(30)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
    [DateRange.Last90Days]: [
      fTruncateHour(fSubDate(90)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
    [DateRange.Last180Days]: [
      fTruncateHour(fSubDate(180)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
    [DateRange.Last1Year]: [
      fTruncateHour(fSubDate(365)).toISOString(),
      fTruncateHour(new Date()).toISOString(),
    ],
  };

export const enum Granularity {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

/**
 * Date range to allowed granularity options
 */
export const dateRangeToGranularityMap: { [key in DateRange]: Granularity[] } =
  {
    [DateRange.Last24Hours]: [Granularity.Hour],
    [DateRange.Last7Days]: [Granularity.Hour, Granularity.Day],
    [DateRange.Last30Days]: [Granularity.Day, Granularity.Week],
    [DateRange.Last90Days]: [
      Granularity.Day,
      Granularity.Week,
      Granularity.Month,
    ],
    [DateRange.Last180Days]: [
      Granularity.Day,
      Granularity.Week,
      Granularity.Month,
    ],
    [DateRange.Last1Year]: [
      Granularity.Day,
      Granularity.Week,
      Granularity.Month,
      Granularity.Year,
    ],
  };

export const daysNameSorted = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getDayHours = () => {
  return times(24, (i) => {
    const hour = i % 12 || 12;
    const period = i < 12 ? 'am' : 'pm';
    return `${hour}${period}`;
  });
};
