import { CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: any) => void;
      };
    };
  }
}
interface HubSpotFormProps {
  portalId: string;
  formId: string;
  email?: string;
}

function loadScript(src: string, id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error(`Failed to load ${src}`));
    };
    document.body.appendChild(script);
  });
}

export default function HubSpotForm({
  portalId,
  formId,
  email,
}: HubSpotFormProps) {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    // Load the HubSpot script dynamically
    loadScript('//js.hsforms.net/forms/v2.js', 'hubspot-forms-script')
      .then(() => {
        setIsLoading(false);
        // Once the script is loaded, create the form
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: portalId,
            formId: formId,
            target: '#hubspot-form',
            onFormReady: function (form: HTMLFormElement) {
              // If an email is provided, set the hidden email field's value
              if (email) {
                const emailField = form.querySelector("input[name='email']");
                if (emailField) {
                  (emailField as HTMLInputElement).value = email;
                }
              }

              // set label styles for dark mode
              const labels = form.querySelectorAll('label');
              labels.forEach((label) => {
                (label as HTMLLabelElement).style.color =
                  theme.palette.text.primary;
              });
            },
          });
        }
      })
      .catch((error) => {
        console.error('Error loading HubSpot script:', error);
        setIsLoading(false);
      });
  }, [portalId, formId, email]);

  return (
    <div>
      {isLoading ? (
        <CircularProgress
          size={24}
          sx={{
            color: 'grey.500',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : (
        <div id="hubspot-form"></div>
      )}
    </div>
  );
}
