// importing Thoughtspot logo as SVG

const ThoughtSpotIconSVG = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="147 -51.7 215 214.7"
    xmlSpace="preserve"
  >
    <g id="BG"></g>
    <g id="Art">
      <g>
        <g>
          <circle cx="335.2" cy="136.3" r="26.8"></circle>
          <polygon
            points="360.1,1.6 147,1.6 147,15 220.2,15 220.2,161.4 233.6,161.4 233.6,15 247,15 247,161.4 260.1,161.4 260.1,15 
      273.5,15 273.5,161.4 286.9,161.4 286.9,15 360.1,15 			"
          ></polygon>
          <rect x="147" y="-24.9" width="213.1" height="13.4"></rect>
          <rect x="147" y="-51.7" width="213.1" height="13.4"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default ThoughtSpotIconSVG;
