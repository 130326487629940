import { Box, MenuItem, Typography, alpha } from '@mui/material';
import React from 'react';

export type MiniNavBarItem = {
  value: string;
  label: string;
  icon: React.ReactNode;
  isEnabled: boolean;
  component: React.ReactNode;
};

interface MiniNavBarProps {
  currentValue: string;
  onChange: (newValue: string) => void;
  menuItems: MiniNavBarItem[];
}

const MiniNavBar = (props: MiniNavBarProps) => {
  const { currentValue, onChange, menuItems } = props;

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.neutral,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
        '& .Mui-selected': {
          '& .MuiTypography-root': {
            fontWeight: 600,
          },
        },
      }}
    >
      {menuItems.map((item) => (
        <MenuItem
          key={item.value}
          onClick={(event) => onChange(item.value)}
          selected={item.value === currentValue}
          sx={{
            borderRadius: 1,
            '& svg': {
              mr: 1,
              width: 20,
              height: 20,
              color: (theme) => alpha(theme.palette.text.primary, 0.8),
            },
            color: 'text.primary',
          }}
        >
          {item.icon}
          <Typography variant="body1" fontWeight={400}>
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </Box>
  );
};

export default MiniNavBar;
