import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  themeMode?: string;
  disabledLink?: boolean;
  isCollapsed?: boolean;
}

function RevefiLogo({
  themeMode = 'light',
  disabledLink = false,
  isCollapsed = false,
  sx,
}: Props) {
  const fillColor = themeMode === 'dark' ? '#FFFFFF' : '#161C24';

  const logo = !isCollapsed ? (
    <Box sx={{ width: 150, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595 122" fill="none">
        <path
          d="M3.12604e-05 81.1524L0.000882404 112.529C0.00092421 114.07 1.67064 115.032 3.00395 114.259L68.004 76.578C68.6211 76.2202 69.001 75.5609 69.0009 74.8476L69.0001 43.4711C69.0001 41.9299 67.3303 40.9679 65.997 41.7409L0.99697 79.422C0.379877 79.7798 1.19112e-05 80.4391 3.12604e-05 81.1524Z"
          fill={fillColor}
        />
        <path
          d="M61.0148 38.7333L34 54.4193C33.3813 54.7786 32.6179 54.78 31.9978 54.423L1.00215 36.577C0.382187 36.22 8.39233e-05 35.5591 8.39233e-05 34.8437V3.46643C8.39233e-05 1.92631 1.66776 0.964127 3.00109 1.73496L61.0115 35.2723C62.3421 36.0415 62.3439 37.9616 61.0148 38.7333Z"
          fill={fillColor}
        />
        <path
          d="M78.0078 102.273L56.0036 89.5785C55.3834 89.2206 54.6192 89.2217 53.9999 89.5812L26.9884 105.265C25.6586 106.037 25.6613 107.958 26.9933 108.727L48.9983 121.422C49.6186 121.779 50.3828 121.778 51.002 121.419L78.0127 105.735C79.3425 104.963 79.3398 103.042 78.0078 102.273Z"
          fill={fillColor}
        />
        <path
          d="M196 33H122C119 33 117 35.2942 117 38V108.003C117 110.709 119.309 113.003 122 113.003C124.691 113.003 127 110.709 127 108.003V83.0029L159.999 112C160.893 112.699 161.946 113 162.999 113C164.439 113 166.036 112.228 166.999 111C168.658 108.873 168.114 105.667 165.999 104L136.999 78H196C198.691 78 201 75.7058 201 73V38C201 35.2942 198.691 33 196 33ZM191 68.0029H127V43.0029H191V68.0029Z"
          fill={fillColor}
        />
        <path
          d="M292.001 103.003H223V78.0029H261C263.703 78.0029 266 75.7087 266 73.0029C266 70.2971 263.703 68.0029 261 68.0029H223V43.0029L292.001 43C294.704 43 297.001 40.7058 297.001 38C297.001 35.2942 294.703 33.0029 292 33.0029H218C215.297 33.0029 213 35.2942 213 38V108C213 110.706 215.297 112.999 218 112.999H292.001C294.704 112.999 297.001 110.706 297.001 108C297.001 105.294 294.704 103.003 292.001 103.003Z"
          fill={fillColor}
        />
        <path
          d="M468 103.003H399V78.0029H437C439.703 78.0029 442 75.7087 442 73.0029C442 70.2971 439.703 68.0029 437 68.0029H399V43.0029L468.001 43C470.704 43 473 40.7058 473 38C473 35.2942 470.703 33.0029 468 33.0029L394 33C391.297 33 389 35.2942 389 38V108C389 110.706 391.297 112.999 394 112.999H468C470.703 112.999 473 110.706 473 108C473 105.294 470.703 103.003 468 103.003Z"
          fill={fillColor}
        />
        <path
          d="M563 33H490C487.294 33 485 35.2942 485 38V108C485 110.705 487.294 113 490 113C492.706 113 495 110.705 495 108V77.9997H533C535.706 77.9997 538 75.7055 538 72.9997C538 70.2939 535.706 67.9997 533 67.9997H495V43H563C565.706 43 568 40.7058 568 38C568 35.2942 565.706 33 563 33Z"
          fill={fillColor}
        />
        <path
          d="M589 33C586.235 33 584 35.2942 584 38V108C584 110.706 586.235 112.996 589 112.996C591.765 112.996 594.001 110.706 594.001 108V38C594.001 35.2942 591.765 33 589 33Z"
          fill={fillColor}
        />
        <path
          d="M377.85 33.8484C375.615 32.3207 372.572 32.9137 371.055 35.165L327.185 100.301L300.941 61.3161C299.424 59.0648 296.38 58.4719 294.145 59.9995C291.91 61.5272 291.331 64.5927 292.838 66.8439L323.133 111.839C323.133 111.839 323.213 111.92 323.253 111.97C323.433 112.211 323.622 112.442 323.852 112.653C323.952 112.744 324.051 112.824 324.161 112.915C324.261 112.995 324.341 113.085 324.451 113.156C324.58 113.236 324.71 113.307 324.84 113.377C324.89 113.407 324.95 113.437 324.999 113.467C325.309 113.628 325.638 113.749 325.967 113.829C326.027 113.849 326.087 113.859 326.147 113.869C326.496 113.95 326.845 114 327.195 114C327.544 114 327.893 113.95 328.243 113.869C328.302 113.859 328.362 113.849 328.422 113.829C328.751 113.739 329.081 113.628 329.39 113.467C329.45 113.437 329.5 113.407 329.55 113.377C329.679 113.307 329.809 113.236 329.939 113.156C330.049 113.085 330.129 112.995 330.228 112.915C330.338 112.834 330.438 112.744 330.538 112.653C330.757 112.442 330.957 112.221 331.126 111.97C331.166 111.92 331.206 111.889 331.246 111.839L379.158 40.6927C380.674 38.4414 380.086 35.376 377.85 33.8484Z"
          fill={fillColor}
        />
      </svg>
    </Box>
  ) : (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        viewBox="206.779 146.754 19.979 29.758"
        width="19.979"
        height="29.758"
      >
        <path
          d="M 206.779 166.479 L 206.779 174.245 C 206.779 174.627 207.201 174.865 207.539 174.674 L 223.975 165.347 C 224.131 165.258 224.226 165.095 224.226 164.919 L 224.226 157.151 C 224.226 156.77 223.805 156.533 223.468 156.724 L 207.032 166.052 C 206.875 166.139 206.779 166.303 206.779 166.479 Z"
          fill={fillColor}
        ></path>
        <path
          d="M 222.208 155.979 L 215.376 159.862 C 215.22 159.951 215.027 159.952 214.87 159.863 L 207.033 155.446 C 206.876 155.358 206.779 155.194 206.779 155.016 L 206.779 147.251 C 206.779 146.869 207.201 146.63 207.538 146.822 L 222.207 155.123 C 222.544 155.313 222.544 155.789 222.208 155.979 Z"
          fill={fillColor}
        ></path>
        <path
          d="M 226.505 171.707 L 220.941 168.565 C 220.783 168.476 220.59 168.476 220.434 168.565 L 213.604 172.447 C 213.268 172.64 213.268 173.114 213.606 173.305 L 219.169 176.447 C 219.326 176.534 219.519 176.534 219.676 176.446 L 226.506 172.565 C 226.843 172.372 226.842 171.897 226.505 171.707 Z"
          fill={fillColor}
        ></path>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/monitors">{logo}</RouterLink>;
}

export default RevefiLogo;
