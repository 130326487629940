import React, { createContext, useContext, ReactNode, useState } from 'react';

import { UserToTenantInfoResponse } from '@ui/gql-client';

interface AppContextData {
  tenantInfo: UserToTenantInfoResponse;
}

// Create the Context
interface AppContextType {
  appContext: AppContextData;
  updateAppContext: (updatedData: Partial<AppContextData>) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('AppContext must be used within a MyContextProvider');
  }
  return context;
}

interface AppContextProviderProps {
  children: ReactNode;
}
function AppContextProvider({ children }: AppContextProviderProps) {
  const initialAppContext: AppContextData = {
    tenantInfo: {} as UserToTenantInfoResponse,
  };
  const [appContext, setAppContext] =
    useState<AppContextData>(initialAppContext);

  const updateAppContext = (updatedData: Partial<AppContextData>) => {
    setAppContext((prevData) => ({ ...prevData, ...updatedData }));
  };

  const contextValue = {
    appContext,
    updateAppContext,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export { AppContextProvider, useAppContext };
