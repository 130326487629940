import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import { ReactNode } from 'react';

interface DialogProps {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  loading?: boolean;
  dialogTitle: string;
  dialogContent: ReactNode;
  cancelAction?: string;
  action: string;
}

export default function ConfirmationDialog(props: DialogProps) {
  const {
    open,
    onClose,
    onConfirm,
    loading,
    dialogTitle,
    dialogContent,
    action,
  } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          {props.cancelAction || 'Cancel'}
        </Button>
        <Box sx={{ ml: 2, position: 'relative' }}>
          <Button
            color="error"
            variant="contained"
            size="medium"
            onClick={() => {
              onConfirm();
              onClose();
            }}
            disabled={loading}
          >
            {action}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: 'grey.500',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
