import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  List,
  ListItem,
  Link,
  Typography,
  CircularProgress,
} from '@mui/material';

// hooks
import { useSettings, useResponsive, useCollapseDrawer } from '@ui/components';

// config
import { HEADER, NAVBAR } from '@ui/components';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import {
  Configuration,
  TenantSubtype,
  TenantType,
  useGetAllConfigQuery,
} from '@ui/gql-client';
import { useUrlParamContext } from '@ui/components';
import { useTheme } from '@mui/material/styles';
import { footerLinks } from './navbar/NavConfig';
import DemoModeBanner from './header/components/DemoModeBanner';
import {
  currentDataSourceIdAtom,
  dataSourcesAtom,
  tenantTypeSubtypeAtom,
} from '@ui/states';
import { useAtom, useSetAtom } from 'jotai';
// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
    height: '100vh',
  },
}));

// ----------------------------------------------------------------------

//The data source dropdown is shown only for the paths matching one of these
const dataSourcePaths = [
  '/tables',
  '/views',
  '/tasks',
  '/tags',
  '/topquestions',
  '/usage',
  '/feed',
  '/monitor',
  '/warehouses',
  '/account',
];

const isValidDsId = (dsId: string | null, configList: Configuration[]) => {
  if (dsId === 'null' || dsId === '' || dsId === '0') {
    return false;
  }
  return configList.some((config: Configuration) => {
    return config.id.toString() === dsId;
  });
};

export default function HomeLayout(props: object) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const theme = useTheme();
  const { themeLayout } = useSettings();
  const { urlParam, updateUrlParam } = useUrlParamContext();

  const location = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const [{ tenantType, tenantSubtype }] = useAtom(tenantTypeSubtypeAtom);

  const {
    data: dataSourceData,
    loading: dataSourceLoadingState,
    error: dataSourceError,
  } = useGetAllConfigQuery();

  const configList = dataSourceData?.Configurations;

  const setDataSources = useSetAtom(dataSourcesAtom);
  const setCurrentDsId = useSetAtom(currentDataSourceIdAtom);

  useEffect(() => {
    // FIXME(@joyce-revefi): Data sources state will not be set for dbt plan
    if (configList && configList?.length > 0) {
      const isValid = isValidDsId(urlParam, configList);
      let currentConfig: Configuration | undefined;
      if (isValid) {
        currentConfig = configList.find((config: Configuration) => {
          return config.id.toString() === urlParam;
        });
        setCurrentDsId(Number(urlParam));
      } else {
        currentConfig = configList[0];
        updateUrlParam(currentConfig.id.toString());
        setCurrentDsId(currentConfig.id);
      }
      setDataSources(
        configList.map((config) => ({
          id: config.id,
          name: config.name || config.alternateName || 'Data warehouse',
          type: config.type,
          bigQueryMultiProjectEnabled:
            config.bigqueryConfig?.multiProjectEnabled,
        }))
      );
    } else {
      // if there are no data sources, clear the data source jotai states
      setDataSources([]);
      setCurrentDsId(null);
    }
  }, [configList, urlParam]);

  // FIXME(@joyce-revefi): temporary solution to hide data source dropdown for dbt plan
  const showDataSourceDropdown = useMemo(() => {
    return dataSourcePaths.some((path) => {
      return (
        location.pathname.includes(path) &&
        tenantSubtype !== TenantSubtype.TenantSubtypeDbt
      );
    });
  }, [location.pathname]);

  if (dataSourceLoadingState) {
    return <CircularProgress color="primary" />;
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DemoModeBanner />
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
        showDataSourceDropdown={showDataSourceDropdown}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <MainStyle collapseClick={collapseClick}>
        <Box sx={{ minHeight: '100vh' }}>
          <Outlet />
        </Box>
        <Box
          sx={{
            minHeight: 50,
            borderTopStyle: 'solid',
            marginTop: 10,
            borderColor: theme.palette.grey[500_12],
            fontSize: theme.typography.caption,
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'row',
              float: 'right',
            }}
          >
            {footerLinks.map((list) => (
              <ListItem
                sx={{
                  width: 'auto',
                }}
                key={list.name}
              >
                <Typography>
                  <Link
                    href={list.href}
                    target="_blank"
                    color="text.secondary"
                    sx={{
                      fontSize: theme.typography.caption,
                    }}
                  >
                    {list.name}
                  </Link>
                </Typography>
              </ListItem>
            ))}
            <ListItem
              sx={{
                width: 'auto',
                color: 'text.secondary',
              }}
            >
              &copy; {new Date().getFullYear()} All rights reserved
            </ListItem>
          </List>
        </Box>
      </MainStyle>
    </Box>
  );
}
