/**
 * @fileoverview Displays the top banner and modal if needed
 * Created on 25/09/23
 */
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography,
} from '@mui/material';
import withSwitchToDemoProp from '../../../../routes/onboarding/components/withSwitchToDemoProp';
import { useEffect, useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Iconify } from '@ui/components';

interface DemoModeBannerProps {
  isDemoMode: boolean;
  exitDemo: () => void;
}
const DemoModeBanner: React.FC<DemoModeBannerProps> = ({
  isDemoMode,
  exitDemo,
}) => {
  const [open, setOpen] = useState(false);
  const hideDemoWelcomeModal = useFeatureFlagEnabled('hideDemoWelcomeModal');

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Explicitly check for false because undefined means the feature flag is not fetched yet
    if (isDemoMode && hideDemoWelcomeModal === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isDemoMode, hideDemoWelcomeModal]);

  return isDemoMode ? (
    <AppBar
      position="fixed"
      sx={{
        background: 'rgb(253, 169, 45, 0.6)',
        zIndex: 1300,
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          minHeight: 28,
          cursor: 'pointer',
          justifyContent: 'center',
          padding: 0.5,
        }}
        onClick={exitDemo}
      >
        <Iconify
          width={28}
          height={28}
          icon="ph:warning"
          marginRight={2}
          color={(theme) => theme.palette.text.primary}
        />
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            textAlign: 'center',
            color: 'black',
          }}
        >
          You are currently viewing Revefi in Demo Mode. Click here to exit and
          resume your workflow.
        </Typography>
      </Toolbar>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Welcome to our Demo Account
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <Typography
            component="span"
            variant="body2"
            sx={{ color: 'text.primary' }}
          >
            This showcases Revefi in action in <strong>read-only</strong> mode:
            <ul>
              <li>
                Tables with different load frequencies (hourly to monthly)
              </li>
              <li>
                TPCH SF10 dataset transformed into staging and final tables
                using dbt cloud
              </li>
              <li>
                Random anomalies injected to demonstrate automatic detection
              </li>
              <li>
                Detailed root cause and impact assessment based on lineage
              </li>
              <li>
                Integration with BI solution (ThoughtSpot) demonstrating
                end-to-end capabilities
              </li>
            </ul>
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingTop: 0 }}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            size="medium"
            onClick={onClose}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  ) : null;
};

export default withSwitchToDemoProp<any>(DemoModeBanner);
