import numeral from 'numeral';
import {
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { isUndefined } from 'lodash';

// ----------------------------------------------------------------------

export const localeString2DmFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

export const localeString4DmFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 4,
});

export function fRoundNumberToTwoDecimals(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fCurrencyNoSymbol(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(num: string | number) {
  return Number(num);
}

export function fFloorNumber(num: string | number) {
  return Math.floor(num as number);
}

export function fFloorNumberToString(num: string | number) {
  return Math.floor(num as number).toLocaleString();
}

export function fNumberToMinAndSec(num: number) {
  if (isNaN(num)) return '';
  const minutes = ~~(num / 60);
  const seconds = Math.ceil(num % 60);
  if (minutes) {
    return `${minutes} min  ${seconds} sec`;
  } else {
    return `${seconds} sec`;
  }
}

export function fShortenNumberToTwoDecimals(number: string | number) {
  return numeral(number).format('0.00');
}
export function fShortenNumberToFourDecimals(number: string | number) {
  return numeral(number).format('0.0000');
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00 a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

/* Converts large numbers to K, M, B notation (1.3e6 = 1.3M, 1e9 = 1B) */
export function fCompactNumber(num: number) {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(num);
}

export function convertToGB(bytes: number, textDisplay?: boolean) {
  if (!isNaN(bytes)) {
    const valueInGB = bytes / (1024 * 1024 * 1024);
    return Number(valueInGB.toFixed(2));
  } else {
    return 0;
  }
}

/* Converts values in bytes to B, KB, MB, GB accordingly */
export function fBytes(bytes: number) {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const dm = 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  // i indicates which unit to use, 0 = B, 1 = KB, 2 = MB, 3 = GB
  // ln (bytes) / ln (1024) = log base 1024 of bytes
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const result = localeString2DmFormatter.format(bytes / Math.pow(k, i));
  return `${result} ${sizes[i]}`;
}

export function epochToTimeDiffWithCollectionPeriod(
  epoch: number,
  collectionPeriod: number
) {
  const collectionPeriodDate = new Date(collectionPeriod * 1000);
  collectionPeriodDate.setUTCHours(0, 0, 0, 0);
  const diff = epoch * 1000 - collectionPeriodDate.getTime();
  return diff / 1000 / 60 / 60;
}

export function millisToSecondsConditional(millis: any) {
  if (isNaN(millis)) return 'N/A';
  return millis / 1000;
}

export function millisToSeconds(millis: number) {
  if (isNaN(millis)) return 0;
  return millis / 1000;
}
export function millisecondsToHours(ms: number): number {
  return Math.round(ms / (1000 * 60 * 60));
}

export const slotsMsToSValueGetter = (params: GridValueGetterParams) => {
  return params.value ? params.value / 1000 : 0;
};

export function timeStampValueFormatter(value: string): string {
  return new Date(`${value}Z`).toUTCString();
}

export function integerGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return isUndefined(params.value) ? '' : params.value.toLocaleString();
}

export function decimalGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return isUndefined(params.value)
    ? ''
    : localeString2DmFormatter.format(params.value);
}

export function fourDecimalGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return isUndefined(params.value)
    ? ''
    : localeString4DmFormatter.format(params.value);
}

export function bytesGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  if (typeof params.value === 'number') {
    return isUndefined(params.value) ? '' : fBytes(params.value);
  } else {
    return '';
  }
}

export function percentageValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return numeral(params.value).format('0.0%');
}

export function timeGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return params.value
    ? fNumberToMinAndSec(params.value)
    : fNumberToMinAndSec(0);
}

export function millisecondsGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return params.value ? fNumberToMinAndSec(params.value / 1000) : '';
}

export function timeStampGridValueFormatter(
  params: GridValueFormatterParams<string>
) {
  return params.value ? timeStampValueFormatter(params.value) : '';
}

export function warehouseSizeGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return isUndefined(params.value) || params.value === 0 ? 'N/A' : params.value;
}

export function currencyGridValueFormatter(
  params: GridValueFormatterParams<number>
) {
  return isUndefined(params.value) ? '' : fCurrency(params.value);
}

export function conditionalQueryTimeFormatter(
  params: GridValueFormatterParams<string>
) {
  return params.value === 'N/A'
    ? 'N/A'
    : isUndefined(params.value)
    ? ''
    : localeString2DmFormatter.format(Number(params.value));
}
