import TagManager from 'react-gtm-module';

declare let window: {
  dataLayer: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  gtag: Function;
};

interface userTrack {
  revefiUserId: string;
  tenantId: number;
  tenantName: string;
}

interface userPilotArgs {
  revefiUserId?: string;
  tenantId?: number;
  tenantName?: string;
  demoMode?: boolean;
}
const tagManagerArgs = {
  gtmId: process.env['VITE_REACT_APP_GTM_MEASUREMENT_ID'] ?? '',
};

let userPilotArgs = {};

export const initializeGA = () => {
  TagManager.initialize(tagManagerArgs);
};

export const setGAUser = (user: userTrack): void => {
  window.dataLayer.push(user);
  userPilotArgs = user;
  trackEvent('gtagInitialized', user);
};

export const trackEvent = (eventName: string, eventObj: object): void => {
  window.dataLayer.push({ event: eventName, ...eventObj });
};

export const triggerUserPilotIdentify = (data: userPilotArgs): void => {
  trackEvent('gtagInitialized', {
    ...userPilotArgs,
    ...data,
  });
};
