import { gql } from '@apollo/client';

export const MONITOR_SUMMARY_CARD = gql`
  query GetConfigSummary {
    ConfigurationSummary {
      label
      value
    }
    MetricEvaluationSummary {
      failedTablesCount
      passedTablesCount
      passedDqCheckEvaluationsCount
      failedDqCheckEvaluationsCount
    }
    NotificationSummary {
      notSentIndividuallyCount
      sentIndividuallyCount
    }
  }
`;
