import {
  format,
  getTime,
  formatDistanceToNow,
  subDays,
  subHours,
  subMonths,
} from 'date-fns';

// ----------------------------------------------------------------------

export const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

export const getTimezoneOffset = (date: Date) =>
  date.getTimezoneOffset() * 60 * 1000;

export function fDateWithErrorHandling(date: Date | string | number) {
  if (date === undefined || date === null) {
    return '-';
  }
  try {
    return format(new Date(date), 'dd MMMM yyyy');
  } catch (err) {
    return '-';
  }
}

export function fDateTimeWithErrorHandling(date: Date | string | number) {
  if (date === undefined || date === null) {
    return '-';
  }
  try {
    return fDateTime(date);
  } catch (err) {
    return '-';
  }
}

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fSubDaysFromDate(date: Date, days: number) {
  return subDays(date, days);
}

export function fSubDate(days: number) {
  return subDays(new Date(), days);
}

/**
 * truncates a date to its UTC year, month, day, and hour, and
 * then adjusts it by the current timezone offset to get the date in current timezone
 * @param date
 */
export function fTruncateHour(date: Date) {
  const truncatedUTCDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours()
  );
  return new Date(
    truncatedUTCDate.getTime() - getTimezoneOffset(truncatedUTCDate)
  );
}

/**
 * Truncates a date to its UTC year, month, and day, and
 * then adjusts it by the current timezone offset to get the date in current timezone
 * @param date
 */
export function fTruncateDate(date: Date) {
  const truncatedUTCDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  );
  return new Date(
    truncatedUTCDate.getTime() - getTimezoneOffset(truncatedUTCDate)
  );
}

/**
 * truncates a date to its UTC year and month
 * then adjusts it by the current timezone offset to get the date in current timezone
 * @param date
 */
export function fTruncateMonth(date: Date) {
  const truncatedUTCDate = new Date(date.getUTCFullYear(), date.getUTCMonth());
  return new Date(
    truncatedUTCDate.getTime() - getTimezoneOffset(truncatedUTCDate)
  );
}

export function fSubWholeMonthsFromDate(date: Date, months: number) {
  return fTruncateMonth(subMonths(date, months));
}

export function formattedUTCTime(utcTime: number | null | undefined) {
  if (utcTime) {
    return new Date(utcTime * 1000).toUTCString();
  } else {
    return '';
  }
}

export function utcDateTimeToLocaleStringGMT(dateTime: number | Date | string) {
  return format(new Date(dateTime), "EEE, dd MMM yyyy HH:mm:ss 'GMT'");
}

export function utcDateTimeToLocaleStringWithoutTime(
  dateTime: number | Date | string
) {
  return format(new Date(dateTime), 'EEE, dd MMM yyyy');
}

export function fSubHours(hours: number) {
  return subHours(new Date(), hours);
}

/**
 * Parses the utc timestamp string to return the correct date in local timezone
 * Eg. a date like 2023-08-30T08:10:00.000 in UTC-7 will give 2023-08-30 01:10:00 Date
 * @param date
 */
export function parseISOString(date: string | Date | number) {
  return new Date(new Date(date).getTime() - timezoneOffset);
}

export function formatSecondsToHoursMinutesSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}h ${minutes}m ${remainingSeconds}s`;
}

export function formatSecondsToHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
}
